const ordersListFields = [
  { label: 'Numero ordine', value: 'id' },
  { label: 'Nome', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Data di prenotazione', value: 'bookingDate' },
  { label: 'Numero di persone', value: 'numberOfPeople' },
  { label: 'Cantina', value: 'wineryName' },
  { label: 'Totale', value: 'total' },
  { label: 'Telefono cliente', value: 'phone' },
];

const wineryListFields = [
  { label: 'ID', value: 'id' },
  { label: 'Nome', value: 'name' },
  { label: 'Indirizzo', value: 'address' },
  { label: 'Telefono', value: 'phone' },
  { label: 'Email', value: 'email' },
  { label: 'Sito web', value: 'website' },
  { label: 'Note', value: 'notes' },
];

const toursListFields = [
  { label: 'ID', value: 'id' },
  { label: 'Nome', value: 'name' },
  { label: 'Indirizzo', value: 'address' },
  { label: 'Telefono', value: 'phone' },
  { label: 'Email', value: 'email' },
  { label: 'Sito web', value: 'website' },
  { label: 'Note', value: 'notes' },
];

const usersListFields = [
  { label: 'Nome', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Tipologia permessi', value: 'role' },
  { label: 'Contatto', value: 'contact' },
];

const vouchersListFields = [
  { label: 'Codice voucher', value: 'code' },
  { label: 'Ordine', value: 'order' },
  { label: 'Nome e cognome destinatario', value: 'recipientName' },
  { label: 'Nome e cognome mittente', value: 'senderName' },
  { label: 'Saldo', value: 'balance' },
  { label: 'Data di creazione', value: 'createdAt' },
  { label: 'Data di scadenza', value: 'expirationDate' },
  { label: 'Data di consegna', value: 'deliveryDate' },
  { label: 'Email destinatario', value: 'recipientEmail' },
];

// function that returns the correct array of fields based on the parameter passed
const getExportFields = (listName) => {
  switch (listName) {
    case 'ordersList':
      return ordersListFields;
    case 'contactsList':
      return wineryListFields;
    case 'productsList':
      return toursListFields;
    case 'usersList':
      return usersListFields;
    case 'vouchersList':
      return vouchersListFields;
    default:
      return [];
  }
};

export default getExportFields;
