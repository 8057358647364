/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CCard,
  CCardHeader,
  CCardBody,
  CFormSwitch,
} from '@coreui/react';

// SERVICES
import PromoCodesService from 'src/services/api/PromoCodesService';

// COMPONENTS
import AppBaseDetail from 'src/components/ui/Detail/AppBaseDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';

// UTILS
import utils from 'src/services/api/utils/utils';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import { da } from 'date-fns/locale';

const PromoCodeDetail = () => {
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm();
  const validFromDate = watch('valid_from');
  const [state, setState] = useState({
    loading: true,
    model: null,
  });

  const promoCodesService = new PromoCodesService();

  const formatModel = (response) => {
    // min_spend, active, n_of_uses, max_of_uses, max_of_uses_per_person, combinable, winery_discount, valid_for_tours, valid_for_vouchers, allowed_tour_categories, allowed_users, claimed_by, is_welcome_code
    const newModel = { ...response.data };
    setValue('code', newModel.code || '');
    setValue('description', newModel.description || '');
    setValue('value', newModel?.discount?.type === 'percentage' ? `${newModel?.discount?.value}%` : utils.formatCurrency({ number: newModel?.discount?.value }));
    setValue('valid_from', newModel.valid_from ? format(new Date(newModel.valid_from), 'yyyy-MM-dd') : '');
    setValue('valid_to', newModel.valid_to ? format(new Date(newModel.valid_to), 'yyyy-MM-dd') : '');
    setValue('min_spend', utils.formatCurrency({ number: newModel.min_spend }));
    setValue('n_of_uses', newModel.n_of_uses);
    setValue('max_of_uses', newModel.max_of_uses);
    setValue('max_of_uses_per_person', newModel.max_of_uses_per_person);
    setValue('combinable', newModel.combinable);
    setValue('active', newModel.active);
    setValue('valid_for_tours', newModel.valid_for_tours);
    setValue('valid_for_vouchers', newModel.valid_for_vouchers);
    setValue('winery_discount', newModel.winery_discount);
    setValue('is_welcome_code', newModel.is_welcome_code);
    return newModel;
  };

  const onSubmit = (data) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ ...state, loading: false, error: response?.error });
        reject(new Error(response?.data?.message));
      };
      const body = {
        ...data,
        // removed the currency symbol or percentage sign at the end of the value
        min_spend: parseFloat(data.min_spend.replace(/[^0-9,.-]+/g, '').replace(',', '.')),
        value: parseFloat(data.value.replace(/[^0-9,.-]+/g, '').replace(',', '.')),
        // convert the date to ISO format
        valid_from: new Date(data.valid_from).toISOString(),
        valid_to: data.valid_to ? new Date(data.valid_to).toISOString() : null,
      };
      promoCodesService.updateItem(state.model['_id'], body, okEditCallback, koEditCallback);
    });

    toast.promise(
      savePromise,
      {
        loading: 'Attendere, salvando le modifiche...',
        success: 'Codice sconto salvato con successo!',
        error: (err) => `Ops, si è verificato un errore! ${err?.message || ''}`,
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      },
    );
  };

  useEffect(() => {
    if (id !== null && typeof id !== 'undefined') {
      const okGetCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      promoCodesService.getItem(id, okGetCallback, koGetCallback);
    }
  }, [id]);

  const handleReset = () => {
    const resetModel = {
      code: state.model.code || '',
      min_spend: utils.formatCurrency({ number: state.model.min_spend }),
      n_of_uses: state.model.n_of_uses || 0,
      max_of_uses: state.model.max_of_uses || 0,
      max_of_uses_per_person: state.model.max_of_uses_per_person || 0,
      combinable: state.model.combinable || null,
      active: state.model.active || null,
      valid_from: format(new Date(state.model.valid_from), 'dd/MM/yyyy'),
      valid_to: format(new Date(state.model.valid_to), 'dd/MM/yyyy'),
      valid_for_tours: state.model.valid_for_tours || false,
      valid_for_vouchers: state.model.valid_for_vouchers || false,
      winery_discount: state.model.winery_discount || false,
      is_welcome_code: state.model.is_welcome_code || false,
    };

    reset({ ...resetModel });
  };

  if (state?.loading === true) return <AppLoadingSpinner />;

  return (
    <AppBaseDetail
      sectionId="promo-codes"
      type="codice sconto"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
    >
      <CForm className="row g-3 mt-3" onSubmit={handleSubmit(onSubmit)}>
        <CRow className="mb-3">
          <CCol md={12} sm={12}>
            <Controller
              name="code"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <CFormInput
                  disabled
                  invalid={!!errors.code}
                  feedback={
                    errors?.code
                      ? composeErrorFormType(errors.code)
                      : null
                  }
                  type="text"
                  id="code"
                  label="Codice sconto"
                  placeholder="Codice sconto"
                  {...field}
                />
              )}
            />
          </CCol>
        </CRow>
        <CCol md={12} sm={12}>
          <CCard>
            <CCardHeader>Dettagli del codice sconto</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={12} sm={12}>
                  <Controller
                    name="value"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        disabled
                        invalid={!!errors.value}
                        feedback={
                          errors?.value
                            ? composeErrorFormType(errors.value)
                            : null
                        }
                        type="text"
                        id="value"
                        label="Valore"
                        placeholder="Valore codice sconto"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.description}
                        feedback={
                          errors?.description
                            ? composeErrorFormType(errors.description)
                            : null
                        }
                        type="text"
                        id="description"
                        label="Descrizione"
                        placeholder="Descrizione codice sconto"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={6} sm={6}>
                  <Controller
                    name="min_spend"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        readOnly
                        invalid={!!errors.min_spend}
                        feedback={
                          errors?.min_spend
                            ? composeErrorFormType(errors.min_spend)
                            : null
                        }
                        type="text"
                        id="min_spend"
                        label="Spesa minima"
                        placeholder="Spesa minima"
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={6} sm={6}>
                  <Controller
                    name="n_of_uses"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        readOnly
                        invalid={!!errors.n_of_uses}
                        feedback={
                          errors?.n_of_uses
                            ? composeErrorFormType(errors.n_of_uses)
                            : null
                        }
                        type="text"
                        id="n_of_uses"
                        label="Numero di utilizzi"
                        placeholder="Numero di utilizzi"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={6} sm={6}>
                  <Controller
                    name="max_of_uses"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.max_of_uses}
                        feedback={
                          errors?.max_of_uses
                            ? composeErrorFormType(errors.max_of_uses)
                            : null
                        }
                        type="number"
                        id="max_of_uses"
                        label="Numero massimo di utilizzi"
                        placeholder="Numero massimo di utilizzi"
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={6} sm={6}>
                  <Controller
                    name="max_of_uses_per_person"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        readOnly
                        invalid={!!errors.max_of_uses_per_person}
                        feedback={
                          errors?.max_of_uses_per_person
                            ? composeErrorFormType(errors.max_of_uses_per_person)
                            : null
                        }
                        type="text"
                        id="max_of_uses_per_person"
                        label="Numero massimo di utilizzi per persona"
                        placeholder="Numero massimo di utilizzi per persona"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={6} sm={6}>
                  <Controller
                    name="valid_from"
                    control={control}
                    rules={{
                      required: true,
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.valid_from}
                        feedback={
                          errors?.valid_from
                            ? composeErrorFormType(errors.valid_from, 'Inserire una data futura')
                            : null
                        }
                        type="date"
                        id="valid_from"
                        label="Data di inizio validità del codice sconto"
                        placeholder="Inserisci la data di inizio validità del codice sconto"
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={6} sm={6}>
                  <Controller
                    name="valid_to"
                    control={control}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (!value) return true; // Allow empty values
                        const selectedDate = new Date(value);
                        return selectedDate >= new Date(validFromDate);
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        disabled={!validFromDate}
                        invalid={!!errors.valid_to}
                        feedback={
                          errors?.valid_to
                            ? composeErrorFormType(errors.valid_to, 'La data di fine validità deve essere successiva alla data di inizio validità')
                            : null
                        }
                        type="date"
                        id="valid_to"
                        label="Data di fine validità del codice sconto"
                        placeholder="Data di fine validità del codice sconto"
                        min={validFromDate}
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={4} sm={4}>
                  <Controller
                    name="valid_for_tours"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        readOnly
                        id="valid_for_tours"
                        label="Valido per i tour"
                        checked={state?.model?.valid_for_tours}
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={4} sm={4}>
                  <Controller
                    name="valid_for_vouchers"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        readOnly
                        id="valid_for_vouchers"
                        label="Valido per i voucher"
                        checked={state?.model?.valid_for_vouchers}
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={4} sm={4}>
                  <Controller
                    name="combinable"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        readOnly
                        id="combinable"
                        label="Combinabile"
                        checked={state.model.combinable}
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={4} sm={4}>
                  <Controller
                    name="winery_discount"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        readOnly
                        id="winery_discount"
                        label="Sconto su fee cantina"
                        checked={state?.model?.winery_discount}
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={4} sm={4}>
                  <Controller
                    name="active"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        id="active"
                        label="Attiva/Disattiva codice sconto"
                        checked={field.value}
                        onClick={(e) => field.onChange(e.target.checked)}
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={4} sm={4}>
                  <Controller
                    name="is_welcome_code"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormSwitch
                        readOnly
                        id="is_welcome_code"
                        label="Codice di benvenuto"
                        checked={state?.model?.is_welcome_code}
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CForm>
    </AppBaseDetail>
  );
};

export default PromoCodeDetail;
