/* eslint-disable */
import React, { useState } from "react";
import {
  CCol,
  CFormInput,
  CRow,
  CListGroup,
  CListGroupItem,
  CFormLabel,
  CTooltip,
} from "@coreui/react";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import SpecialVouchersService from "src/services/api/SpecialVouchersService";
import AppList from "src/components/ui/List/AppList";
import composeErrorFormType from "src/utils/composeErrorFormType";

//Utils
import utils from "src/services/api/utils/utils";
import regionOptions from "src/utils/regions";

// ICONS
import CIcon from "@coreui/icons-react";
import { cilInfo, cilX } from "@coreui/icons";

function SpecialVouchersList() {
  const [selectedTours, setSelectedTours] = useState([]);
  const [isInsertingPrice, setIsInsertingPrice] = useState(true);
  const [isInsertingRegions, setIsInsertingRegions] = useState(true);
  const specialVouchersService = new SpecialVouchersService();
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const minPrice = watch("minPrice");
  const maxPrice = watch("maxPrice");
  const regions = watch("regions");

  const onChangeSelectedOption = () => (e) => {
    setSelectedTours(e);
    return setValue("new_tour_select", e);
  };

  const onFocusPrice =
    (inputType = "") =>
    () => {
      errors.maxPrice = null;
      errors.minPrice = null;
      setIsInsertingPrice(true);
      setSelectedTours([]);
      if (inputType === "minPrice") {
        minPrice && setValue("maxPrice", "");
      }
    };

  const onBlurPrice =
    (inputType = "") =>
    () => {
      setIsInsertingPrice(false);
      if (inputType === "maxPrice") {
        if (Number(maxPrice) < Number(minPrice)) {
          errors.maxPrice = {
            type: "validate",
          };
        }
      }
    };

  const onFocusRegions = () => {
    errors.regions = null;
    setIsInsertingRegions(true);
  };

  const onBlurRegions = () => {
    if (regions && regions.length === 0) {
      errors.regions = {
        type: "required",
      };
    }
    setIsInsertingRegions(false);
    loadTours();
  };

  const handleRegionChange = (option) => {
    if (option.some((region) => region.value === "all_regions")) {
      setValue("regions", [{ value: "all_regions", label: "Tutta Italia" }]);
    } else {
      setValue("regions", option);
    }
  };

  const loadTours = (filter) =>
    new Promise((resolve) => {
      if (!minPrice || !maxPrice || !regions || regions.length === 0) {
        resolve([]);
        return;
      }
      const okGetTours = (response) => {
        let responseData = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          responseData = response.data.map((currentItem) => {
            return {
              value: currentItem.purchase_option_id,
              product_id: currentItem.product_id,
              purchase_option_id: currentItem.purchase_option_id,
              name: currentItem.product_name,
              package_name: currentItem.purchase_option_name,
              label: `${currentItem.product_name} - ${currentItem.purchase_option_name}`,
            };
          });
        }
        resolve(responseData);
      };
      const koGetTours = () => resolve([]);

      specialVouchersService.getVoucherTours(
        {
          min_price: minPrice >= 0 ? minPrice : 0,
          max_price: maxPrice || 0,
          regions: regions.map((region) => region.value),
        },
        okGetTours,
        koGetTours
      );
    });

  const selectAllTours = () => {
    loadTours().then((tours) => {
      setSelectedTours(tours);
      setValue("new_tour_select", tours);
    });
  };

  const resetSelectedTours = () => {
    setSelectedTours([]);
    setValue("new_tour_select", []);
  };

  const buildColumnsFn = () => [
    {
      key: "voucher_name",
      label: "Nome società",
      sortable: false,
      _props: { scope: "col" },
    },
    {
      key: "voucher_value",
      label: "Valore voucher",
      sortable: false,
      _props: { scope: "col" },
    },
    {
      key: "voucher_quantity",
      label: "Quantità",
      sortable: false,
      _props: { scope: "col" },
    },
    {
      key: "voucher_description",
      label: "Descrizione",
      sortable: false,
      _props: { scope: "col" },
    },
    {
      key: "expiration_date",
      label: "Data di scadenza",
      sortable: false,
      _props: { scope: "col" },
    },
  ];

  const buildRowsFn = (item) => {
    return {
      _id: item._id,
      voucher_name: item.name,
      voucher_value: item?.value,
      voucher_quantity: item?.quantity,
      voucher_description: item?.description,
      expiration_date: item?.expiration_date,
    };
  };

  const mapListFn = (item) => {
    return {
      _id: item._id,
      name: item.name,
      value: utils.formatCurrency({ number: item.value }),
      quantity: item.quantity,
      description: item.description,
      expiration_date: item.expiring_at
        ? format(new Date(item.expiring_at), "dd/MM/yyyy")
        : "-",
    };
  };

  const mapSelectedTours = (tour) => (
    <CListGroupItem key={tour.purchase_option_id}>{tour.label}</CListGroupItem>
  );

  const creationBodyFn = () => (
    <>
      <CRow md={{ cols: 2, gutter: 2 }} className="mb-2">
        <CCol md={6}>
          <Controller
            name="voucher_name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors?.voucher_name}
                feedback={
                  errors?.voucher_name
                    ? composeErrorFormType(errors.voucher_name)
                    : null
                }
                type="text"
                id="voucher_name"
                label="Nome società destinataria voucher"
                placeholder="Inserisci nome"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={6}>
          <Controller
            name="minPrice"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors?.minPrice}
                feedback={
                  errors?.minPrice
                    ? composeErrorFormType(errors.minPrice)
                    : null
                }
                type="number"
                min={0}
                id="minPrice"
                label="Prezzo minimo pacchetti"
                placeholder="Inserisci il prezzo minimo"
                {...field}
                onFocus={onFocusPrice("minPrice")}
                onBlur={onBlurPrice}
              />
            )}
          />
        </CCol>
      </CRow>
      <CRow md={{ cols: 2, gutter: 2 }} className="mb-2">
        <CCol md={6}>
          <Controller
            name="maxPrice"
            disabled={!minPrice}
            control={control}
            rules={{ required: true, validate: (value) => Number(value) >= Number(minPrice) }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors?.maxPrice}
                feedback={
                  errors?.maxPrice
                    ? composeErrorFormType(
                        errors.maxPrice,
                        "Il prezzo massimo deve essere maggiore o uguale al prezzo minimo"
                      )
                    : null
                }
                type="number"
                min={minPrice}
                id="maxPrice"
                label={
                  <>
                    Prezzo massimo pacchetti&nbsp;&nbsp;
                    <CTooltip
                      content={
                        <span>
                          Verranno mostrati i pacchetti dei tour con un
                          prezzo,&nbsp;
                          <b style={{ color: "yellow" }}>per 2 persone</b>, che
                          varia tra il valore minimo e massimo inserito.
                        </span>
                      }
                    >
                      <CIcon
                        icon={cilInfo}
                        className="icon-button cursor-pointer"
                      />
                    </CTooltip>
                  </>
                }
                placeholder="Inserisci il prezzo massimo"
                {...field}
                onFocus={onFocusPrice("maxPrice")}
                onBlur={onBlurPrice("maxPrice")}
              />
            )}
          />
        </CCol>
        <CCol md={6}>
          <Controller
            name="regions"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <>
                <CFormLabel htmlFor="regions">Selezione Regioni</CFormLabel>
                <Select
                  {...field}
                  options={regionOptions}
                  isMulti
                  placeholder="Seleziona una o più regioni"
                  onChange={handleRegionChange}
                  onBlur={onBlurRegions}
                  onFocus={onFocusRegions}
                />
              </>
            )}
          />
        </CCol>
      </CRow>
      <CRow md={{ cols: 2, gutter: 2 }} className="mb-4">
        <CCol md={6}>
          <Controller
            name="quantity"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors?.quantity}
                feedback={
                  errors?.quantity
                    ? composeErrorFormType(errors?.quantity)
                    : null
                }
                type="number"
                id="quantity"
                label="Quantità"
                placeholder="Inserisci quantità"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={6}>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors?.description}
                feedback={
                  errors?.description
                    ? composeErrorFormType(errors?.description)
                    : null
                }
                type="text"
                id="description"
                label="Descrizione"
                placeholder="Inserisci descrizione"
                {...field}
              />
            )}
          />
        </CCol>
      </CRow>
      <CRow md={{ cols: 2, gutter: 2 }}>
        <CCol md={6}>
          {!isInsertingPrice &&
          !isInsertingRegions &&
          minPrice &&
          maxPrice &&
          Number(maxPrice) >= Number(minPrice) &&
          regions &&
          regions.length > 0 ? (
            <Controller
              name="new_tour_select"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <>
                  <div className="d-flex justify-content-between">
                    <CFormLabel htmlFor="new_tour_select">
                      Selezione Tour{" "}
                    </CFormLabel>
                    <CFormLabel
                      onClick={selectAllTours}
                      style={{
                        cursor: "pointer",
                        border: "1px solid gray",
                        paddingRight: "6px",
                        paddingLeft: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      Seleziona tutti
                    </CFormLabel>
                  </div>
                  <AsyncSelect
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        maxHeight: "350px",
                        overflowY: "auto",
                      }),
                    }}
                    defaultOptions
                    loadOptions={loadTours}
                    inputId="new_tour_select"
                    isDisabled={
                      isInsertingPrice ||
                      isInsertingRegions ||
                      !(minPrice && maxPrice && regions && regions.length > 0)
                    }
                    isMulti
                    {...field}
                    isClearable
                    placeholder={"Seleziona uno o più tour"}
                    value={selectedTours}
                    onChange={onChangeSelectedOption()}
                  ></AsyncSelect>
                </>
              )}
            />
          ) : (
            <>
              <CFormLabel>Selezione Tour</CFormLabel>
              <Select
                required={false}
                isDisabled
                placeholder={"Inserisci prima prezzo e regioni per le opzioni"}
              />
            </>
          )}
        </CCol>
        <CCol md={6}>
          <div className="d-flex justify-content-between">
                    <CFormLabel>
                      Tour selezionati{" "}
                    </CFormLabel>
                    <CFormLabel
                      onClick={resetSelectedTours}
                      className="cursor-pointer"
                    >
                      <CIcon
                        icon={cilX}
                        className="icon-button cursor-pointer text-danger mx-2"
                      />
                    </CFormLabel>
                  </div>
          <div style={{ maxHeight: "350px", overflowY: "auto" }}>
            <CListGroup className="pb-2">
              {selectedTours.length === 0 && (
                <CListGroupItem>Nessun tour selezionato</CListGroupItem>
              )}
              {selectedTours.length > 0 && selectedTours.map(mapSelectedTours)}
            </CListGroup>
          </div>
        </CCol>
      </CRow>
    </>
  );

  return (
    <section id="special_vouchers">
      <AppList
        scrollable
        sectionId="special_vouchers"
        sectionTitle="Lista vouchers speciali"
        SectionServiceClass={SpecialVouchersService}
        sectionPath="/special-vouchers"
        mapListFn={mapListFn}
        buildColumnsFn={buildColumnsFn}
        buildRowsFn={buildRowsFn}
        creationTitle="Nuovo voucher speciale"
        creationBodyFn={() => creationBodyFn()}
        evalCreation={handleSubmit}
        clearCreationModel={() => reset({})}
      />
    </section>
  );
}
export default SpecialVouchersList;
