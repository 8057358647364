/* eslint-disable */
// React
import React, { useState } from "react";

// Services
import AuthService from "src/services/api/authService";

// CoreUI
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilEnvelopeClosed } from "@coreui/icons";

// Toast
import toast, { Toaster } from "react-hot-toast";

// Assets
import Logo from "../../../assets/images/logo/logo_lg.svg";

function ForgotPassword() {
  const [loginForm, setLoginForm] = useState({ email: "" });
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const authService = new AuthService();

  const onChange = (event) => {
    setLoginForm({ ...loginForm, [event.target.name]: event.target.value });
  };

  const sendForgotPswEmail = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (validated === false) setValidated(true);
    if (form.checkValidity() === true) {
      setLoading(true);
      const savePromise = new Promise((resolve, reject) => {
        const okCallback = () => {
          setLoading(false);
          setLoginError(null);
          setLoginForm({ email: "" });
          setValidated(false);
          resolve();
        };

        const koCallback = (error) => {
          setLoading(false);
          setLoginError(
            error?.response?.data?.message ||
              "Errore durante l'invio della email."
          );
          reject();
        };

        authService.resetPassword(loginForm.email, okCallback, koCallback);
      });

      toast.promise(
        savePromise,
        {
          loading: "Invio email...",
          success: "Email inviata con successo.",
          error: "Errore durante l'invio della email.",
        },
        {
          success: {
            duration: 7500,
          },
          error: {
            duration: 7500,
          },
        }
      );
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <Toaster position="top-right" reverseOrder={false} />
        <CRow className="justify-content-center">
          <CCol md={8} lg={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CImage className="login-logo" src={Logo} />
                  <h1>Recupera Password</h1>
                  <p className="text-medium-emphasis">
                    Inserisci il tuo indirizzo email per ricevere le indicazioni
                    su come cambiare la password.
                  </p>
                  {loginError !== null && (
                    <CAlert
                      color="danger"
                      dismissible
                      onClose={() => {
                        setLoginError(null);
                      }}
                    >
                      {loginError}
                    </CAlert>
                  )}
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={sendForgotPswEmail}
                  >
                    <CFormLabel htmlFor="email">Indirizzo email</CFormLabel>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilEnvelopeClosed} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        id="email"
                        name="email"
                        placeholder="you@example.com"
                        value={loginForm.email}
                        onChange={onChange}
                        required
                        feedbackInvalid="Perfavore inserisci un indirizzo email valido."
                        tooltipFeedback
                        onFocus={() => {
                          setLoginError(null);
                        }}
                      />
                    </CInputGroup>
                    <CRow>
                      {loading === true && (
                        <CCol xs={12} className="text-center" color="primary">
                          <CSpinner />
                        </CCol>
                      )}
                      {loading === false && (
                        <CCol xs={12}>
                          <CButton
                            type="submit"
                            disabled={loading === true}
                            color="primary"
                            className="px-4 w-100"
                          >
                            Invia email
                          </CButton>
                        </CCol>
                      )}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default ForgotPassword;
