import React from 'react';
import { CFooter } from '@coreui/react';

function AppFooter() {
  return (
    <CFooter>
      <div>
        <span className="ms-1">
          &copy;
          2022, Vinbacco ® Vinbacco Srl –
          Via Vincenzo Monti 8 Milano – P.IVA 13269340967 – Capitale Sociale € 10.000
        </span>
      </div>
    </CFooter>
  );
}

export default React.memo(AppFooter);
