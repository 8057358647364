/* eslint-disable */
// React
import React, { useState, useEffect } from "react";

// Libs
import { CCol, CRow, CTableDataCell, CTableRow } from "@coreui/react";

// Components
import YearSelector from "src/components/ui/Dashboard/YearSelector";
import WidgetsDropdown from "src/components/ui/Dashboard/Widgets/WidgetsDropdown";
import TopMonthlyChart from "src/components/ui/Dashboard/TopMonthlyChart";
import MonthlyBarChart from "src/components/ui/Dashboard/MonthlyBarChart";

// Utils
import utils from "src/services/api/utils/utils";
import {
  months,
  currentMonth,
  currentYear,
  filterDataBySelectedYear,
  getWidgetData,
} from "src/utils/dashboardUtils";
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";

function DashboardWinery({ userId, children }) {
  const [widgetsData, setWidgetsData] = useState({});
  const [isWidgetDataLoading, setIsWidgetDataLoading] = useState(true);
  const [state, setState] = useState({
    selectedYear: currentYear,
    selectedTab: currentMonth,
  });

  useEffect(() => {
    getWidgetData(
      setWidgetsData,
      setIsWidgetDataLoading,
      state.selectedYear,
      userId
    );
  }, [state.selectedYear]);

  const monthlyBookingsGraphData = {
    labels:
      currentYear === state.selectedYear
        ? months.slice(0, currentMonth + 1)
        : months,
    datasets: [
      {
        label: "Prenotazioni tour",
        backgroundColor: "#42A5F5",
        data: widgetsData?.bookingData?.totalsPerMonth,
      },
    ],
  };

  const widgetsDropdownData = [
    {
      color: "primary",
      total: utils.formatCurrency({
        number: widgetsData?.ordersData?.totalAmountOfYear,
      }),
      title: "Incassi",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.ordersData?.totalsPerMonth,
        state.selectedYear
      ),
    },
    {
      color: "warning",
      total: widgetsData?.bookingData?.totalAmountOfYear,
      title: "Prenotazioni",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.bookingData?.totalsPerMonth,
        state.selectedYear
      ),
    },
  ];

  const mostBookedPackagesData = widgetsData?.bookingData?.topThreePackages;

  const changeSelectedTab = (tab) => {
    let selectedTab = null;
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === tab.toLowerCase()
    );
    if (monthIndex !== -1) {
      selectedTab = monthIndex;
    }
    setState({ ...state, selectedTab });
  };

  const mapTopBookingsTableBody = (item, index) => {
    return (
      <CTableRow v-for="item in tableItems" key={index}>
        <CTableDataCell>{item.label || "-"}</CTableDataCell>
        <CTableDataCell>
          <strong>{item.count || "-"}</strong>
        </CTableDataCell>
      </CTableRow>
    );
  };

  const changeSelectedYear = (e) => {
    setState({ ...state, selectedYear: Number(e.target.value) });
  };

  return (
    <>
      {/* Loading data */}
      {isWidgetDataLoading && (
        <div className="my-4">
          <AppLoadingSpinner />
        </div>
      )}

      {/* Data loaded */}
      {!isWidgetDataLoading && (
        <>
          {/* Selezione anno */}
          <CRow className="my-4">
            <CCol className="d-flex align-items-center gap-2">
              <YearSelector
                startingYear={2022}
                changeSelectedYear={changeSelectedYear}
              />
            </CCol>
          </CRow>

          {/* Widgets */}
          <WidgetsDropdown
            selectedYear={state.selectedYear}
            widgetsData={widgetsDropdownData}
          />

          {/* Prenotazioni mensili */}
          <MonthlyBarChart
            title="Prenotazioni mensili"
            selectedYear={state.selectedYear}
            data={monthlyBookingsGraphData}
            isLoading={isWidgetDataLoading}
          />

          {/* Ultime prenotazioni */}
          {children}

          {/* Pacchetti più prenotati */}
          <CRow>
            <CCol>
              <TopMonthlyChart
                title="Pacchetti più prenotati"
                selectedYear={state.selectedYear}
                selectedTab={state.selectedTab}
                changeSelectedTab={changeSelectedTab}
                tourData={mostBookedPackagesData}
                mapTableBody={mapTopBookingsTableBody}
                tableHeaderCells={["Pacchetto", "Totale prenotazioni"]}
              />
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
}

export default DashboardWinery;
