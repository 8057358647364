/* eslint-disable */
import React from "react";
import AppLoadingSpinner from "../../AppLoadingSpinner";
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from "@coreui/react";
import { getStyle } from "@coreui/utils";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop, cilOptions } from "@coreui/icons";
import WidgetChart from "./WidgetChart";

const WidgetsDropdown = ({ selectedYear, widgetsData }) => {

  const mapWidgetsData = (data, index) => {
   return (
      <CCol sm={6} md={6} lg={3} key={index}>
        <WidgetChart
          selectedYear={selectedYear}
          value={data.total}
          title={data.title}
          color={data.color}
          monthlyData={data.monthlyData}
          noRadius={data.noRadius}
          chartBGColor={data.chartBGColor}
        />
      </CCol>
    );
  };

  return (
    <CRow>
      {widgetsData && widgetsData.length > 0 && widgetsData.map(mapWidgetsData)}
      {/* {widgetsData.map(mapWidgetsData)} */}
      {/* <CCol sm={6} lg={3}>
        <WidgetChart
          selectedYear={selectedYear}
          value={"668 €"}
          percentage={-12.4}
          title={"Totale vendite"}
          color={"primary"}
          monthlyData={[0, 10, 20, 30, 40, 40, 40]}
        />
        <WidgetChart
          selectedYear={selectedYear}
          value={320}
          percentage={-12.4}
          title={"Nuovi clienti"}
          color={"info"}
          monthlyData={[1, 18, 9, 17, 34, 22, 11, 34, 22, 11, 34, 22]}
        />
        <WidgetChart
          selectedYear={selectedYear}
          value={249}
          percentage={24.4}
          title={"Prenotazioni"}
          color={"warning"}
          monthlyData={[78, 81, 80, 45, 34, 12, 40, 84, 84, 51, 55, 40]}
          noRadius
          chartBGColor={"rgba(255,255,255,.2)"}
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="info"
          value={
            <>
              320{" "}
              <span className="fs-6 fw-normal">
                (40.9% <CIcon icon={cilArrowTop} />)
              </span>
            </>
          }
          title={`Nuovi clienti ${selectedYear}`}
          action={
            <CDropdown alignment="end">
              <CDropdownToggle
                color="transparent"
                caret={false}
                className="p-0"
              >
                <CIcon
                  icon={cilOptions}
                  className="text-high-emphasis-inverse"
                />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem>Action</CDropdownItem>
                <CDropdownItem>Another action</CDropdownItem>
                <CDropdownItem>Something else here...</CDropdownItem>
                <CDropdownItem disabled>Disabled action</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          }
          chart={
            <CChartLine
              className="mt-3 mx-3"
              style={{ height: "70px" }}
              data={{
                labels: months,
                datasets: [
                  {
                    // label: "My First dataset",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    // pointBackgroundColor: getStyle("--cui-info"),
                    data: [1, 18, 9, 17, 34, 22, 11, 34, 22, 11, 34, 22],
                    fill: true,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawBorder: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    display: false,
                    grid: {
                      display: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
                elements: {
                  line: {
                    borderWidth: 1,
                  },
                  point: {
                    radius: 4,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="warning"
          value={
            <>
              249{" "}
              <span className="fs-6 fw-normal">
                (24.7% <CIcon icon={cilArrowTop} />)
              </span>
            </>
          }
          title={`Prenotazioni ${selectedYear}`}
          action={
            <CDropdown alignment="end">
              <CDropdownToggle
                color="transparent"
                caret={false}
                className="p-0"
              >
                <CIcon
                  icon={cilOptions}
                  className="text-high-emphasis-inverse"
                />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem>Action</CDropdownItem>
                <CDropdownItem>Another action</CDropdownItem>
                <CDropdownItem>Something else here...</CDropdownItem>
                <CDropdownItem disabled>Disabled action</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          }
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: "70px" }}
              data={{
                labels: months,
                datasets: [
                  {
                    // label: "My First dataset",
                    backgroundColor: "rgba(255,255,255,.2)",
                    borderColor: "rgba(255,255,255,.55)",
                    data: [78, 81, 80, 45, 34, 12, 40, 84, 84, 51, 55, 40],
                    fill: true,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="mb-4"
          color="danger"
          value={
            <>
              148{" "}
              <span className="fs-6 fw-normal">
                (3.6% <CIcon icon={cilArrowTop} />)
              </span>
            </>
          }
          title="Cantine"
          action={
            <CDropdown alignment="end">
              <CDropdownToggle
                color="transparent"
                caret={false}
                className="p-0"
              >
                <CIcon
                  icon={cilOptions}
                  className="text-high-emphasis-inverse"
                />
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem>Action</CDropdownItem>
                <CDropdownItem>Another action</CDropdownItem>
                <CDropdownItem>Something else here...</CDropdownItem>
                <CDropdownItem disabled>Disabled action</CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          }
          chart={
            <CChartBar
              className="mt-3 mx-3"
              style={{ height: "70px" }}
              data={{
                labels: months,
                datasets: [
                  {
                    // label: "My First dataset",
                    backgroundColor: "rgba(255,255,255,.2)",
                    borderColor: "rgba(255,255,255,.55)",
                    data: [78, 21, 80, 45, 34, 12, 40, 84, 84, 51, 55, 40],
                    barPercentage: 0.8,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  x: {
                    grid: {
                      display: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                  y: {
                    grid: {
                      display: false,
                      drawBorder: false,
                      drawTicks: false,
                    },
                    ticks: {
                      display: false,
                    },
                  },
                },
              }}
            />
          }
        />
      </CCol> */}
    </CRow>
  );
};

export default WidgetsDropdown;
