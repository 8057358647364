import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Services
import OrdersService from 'src/services/api/OrdersService';

// Libs
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTableDataCell,
  CTableRow,
  CProgress,
} from '@coreui/react';
import { Link } from 'react-router-dom';

// Utils
import utils from 'src/services/api/utils/utils';
import { getOrderStatus } from 'src/utils/dashboardUtils';

// Components
import DashboardTable from 'src/components/ui/Dashboard/DashboardTable';
import DashboardAdmin from './DashboardAdmin';
import DashboardWinery from './DashboardWinery';

const Dashboard = () => {
  const ordersService = new OrdersService();
  const userData = useSelector((state) => state.user.value);

  const DashboardComponent = {
    superadmin: DashboardAdmin,
    admin: DashboardAdmin,
    winery: DashboardWinery,
    editor: DashboardAdmin,
  }[userData.user_group];

  const [state, setState] = useState({
    tableData: [],
  });

  const getLastBookings = () => {
    ordersService.getList({
      paginate: 10,
      page: 1,
      order: 'desc',
      sort: 'created_at',
      filters: {},
      okCallback: (response) => {
        const { data } = response;
        setState({
          ...state,
          tableData: data,
        });
      },
      koCallback: (error) => {
        throw new Error(error);
      },
    });
  };

  useEffect(() => {
    getLastBookings();
  }, []);

  const lastBookingsTableColumns = [
    'Num. ordine',
    'Nome e data',
    'Tour',
    'Cantina',
    'Totale',
    'Telefono',
    'Stato',
  ];

  const mapTableTours = (item, index) => (
    <li key={index}>{item.name || 'n.a.'}</li>
  );

  const mapTableWineries = (item, index) => (
    <li key={index}>{item.contact_name || 'n.a.'}</li>
  );

  const mapLastBookingsTableBody = (item, index) => {
    const { orderStatus, progressColor, progressValue } = getOrderStatus(item);
    // Phone and last_name hidden if the user group is 'winery' and the order is not confirmed
    const hideFields = userData.user_group === 'winery' && orderStatus.label !== 'Confermato';

    return (
      <CTableRow v-for="item in tableItems" key={index}>
        <CTableDataCell>{item.order_number || '-'}</CTableDataCell>
        <CTableDataCell>
          {hideFields ?
            <div>{item.user?.first_name}</div>
            :
            <div>{item.user?.name}</div> }
          <div className="small text-medium-emphasis">
            {new Date(item.created_at).toLocaleDateString() || '-'}
          </div>
        </CTableDataCell>
        <CTableDataCell>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
            {item.products?.map(mapTableTours)}
          </ul>
        </CTableDataCell>
        <CTableDataCell>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
            {item.products?.map(mapTableWineries)}
          </ul>
        </CTableDataCell>
        <CTableDataCell>
          <strong>
            {utils.formatCurrency({
              number: userData?.user_group === 'winery' ? item?.total_winery : item?.total_amount,
            }) || '-'}
          </strong>
        </CTableDataCell>
        <CTableDataCell>{hideFields ? '-' : item.invoice_data?.phone}</CTableDataCell>
        <CTableDataCell>
          {orderStatus.label}
          <CProgress thin color={progressColor} value={progressValue} />
        </CTableDataCell>
      </CTableRow>
    );
  };

  return (
    <DashboardComponent userId={userData._id} userGroup={userData.user_group}>
      <CRow>
        <CCol xs>
          <CCard className="mb-4">
            <CCardBody>
              <h4 className="card-title mb-2">Ultime prenotazioni</h4>
              <DashboardTable
                tableColumns={lastBookingsTableColumns}
                mapTableBody={mapLastBookingsTableBody}
                tableData={state.tableData}
              />
              <Link to="/orders">
                <CButton color="light" className="mt-4 float-starts">
                  Vai a tutte le prenotazioni
                </CButton>
              </Link>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </DashboardComponent>
  );
};

export default Dashboard;
