/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CForm, CCol, CFormInput,
  CRow, CFormTextarea, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton,
} from '@coreui/react';

import TourService from 'src/services/api/TourService';

import AppDetail from 'src/components/ui/Detail/AppDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';
import AppMultiData from 'src/components/ui/MultiData/AppMultiData';
import Gallery from 'src/components/ui/Images/Gallery/Gallery';
import TimeTable from 'src/components/ui/TimeTable/TimeTable';
import ImageWithPreview from 'src/components/ui/Images/ImageWithPreview';
import ServicesCheckbox from 'src/components/ui/ServicesCheckbox/ServicesCheckbox';
import LanguagesCheckbox from 'src/components/ui/LanguagesCheckbox/LanguagesCheckbox';
import TimesCheckbox from 'src/components/ui/TimesCheckbox/TimesCheckbox';
import DateRangePicker from 'src/components/DateRangePicker/DateRangePicker';
import VercelService from 'src/services/VercelService';
import PackageForm from './Packages/PackageForm';

function ToursDetail() {
  const { id } = useParams();
  const userGroup = useSelector((state) => state.user.value.user_group);
  const [state, setState] = useState({ loading: true, error: null, currentActiveTab: 'package-tab' });
  const [notificationsModal, setNotificationsModal] = useState({ show: false, text: '', tabTarget: 'package-tab' });
  const {
    control, reset, getValues, setValue,
  } = useForm({
    defaultValues: {
      name: '',
      meta_title: '',
      meta_keywords: '',
      meta_description: '',
      base_price: 0,
      abstract: '',
      description: '',
      contact_name: '',
      category_name: '',
      url_friendly_name: '',
      tour_warnings: '',
      attributes: {
        purchase_options: [],
        TimeTable: {},
        coordinates: {},
        languages: [],
        tasting_kind: [],
        tastingTimes: [],
        closingDays: [],
      },
      media_contents: [],
      tags: [],
    },
  });
  const [tourPreviewImage, setTourPreviewImage] = useState(null);
  const [tourMediaContents, setTourMediaContents] = useState([]);

  const vercelService = new VercelService();

  const mapPurchaseOptionsToModel = (purchaseOptionsToMap) => {
    if (!purchaseOptionsToMap) {
      return [];
    }
    return purchaseOptionsToMap.map((option) => {
      const mapped = { ...option };
      mapped.included_services = option.translations?.it?.included_services || [];
      mapped.price = Number.parseFloat(option.price).toFixed(2);
      mapped.fee_amount = Number.parseFloat(option.fee_amount).toFixed(2);
      return mapped;
    });
  };

  const formatModel = (response) => {
    const tourResponseData = { ...response?.data || {} };
    const tourModelData = {};
    tourModelData.product_category_id = tourResponseData.product_category_id;
    tourModelData.contact_id = tourResponseData.contact_id;
    tourModelData.name = tourResponseData.name;
    tourModelData.base_price = tourResponseData.base_price;
    tourModelData.meta_title = tourResponseData.meta_title || '';
    tourModelData.meta_keywords = tourResponseData.meta_keywords || '';
    tourModelData.meta_description = tourResponseData.meta_description || '';
    tourModelData.abstract = tourResponseData?.abstract || '';
    tourModelData.description = tourResponseData?.description || '';
    tourModelData.url_friendly_name = tourResponseData.url_friendly_name;
    tourModelData.contact_name = tourResponseData?.contact?.business_name;
    tourModelData.category_name = tourResponseData?.product_category?.name;
    tourModelData.tour_warnings = tourResponseData?.tour_warnings || '';
    tourModelData.attributes = JSON.stringify(tourResponseData?.attributes) !== '{}'
      ? tourResponseData?.attributes
      : {
        purchase_options: [],
        timeTable: {},
        languages: [],
        closingDays: [],
        coordinates: {},
      };
    tourModelData.attributes.purchase_options = mapPurchaseOptionsToModel(
      tourModelData?.attributes?.purchase_options,
    );
    tourModelData.tags = tourResponseData?.tags || [];
    tourModelData.media_contents = tourResponseData?.media_contents || [];
    tourModelData.published = tourResponseData?.published || false;
    return tourModelData;
  };

  useEffect(() => {
    if (id !== null && typeof id !== 'undefined') {
      const tourService = new TourService();
      const okGetDetails = (response) => {
        const tourData = formatModel(response);
        reset(tourData);
        setState({ ...state, loading: false, model: tourData });
        if (
          Array.isArray(tourData.media_contents)
          && tourData.media_contents.length > 0
        ) {
          setTourMediaContents([...tourData.media_contents.filter((current) => current.type === 'tour_image').sort((a, b) => a.order - b.order)]);
          setTourPreviewImage(tourData.media_contents.find((current) => current.type === 'tour_preview_image'));
        }
      };

      const koGetDetails = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      tourService.getItem(id, okGetDetails, koGetDetails);
    }
  }, []);

  const previewPage = (urlPage) => {
    console.log(`Navigate to ${urlPage}`);
  };

  const mapPurchaseOptionsToUpdate = (purchaseOptionsToMap) => (
    purchaseOptionsToMap.map((option) => {
      const mapped = { ...option };
      mapped.translations = option.translations || { it: { included_services: [] } };
      mapped.translations.it.included_services = option.included_services;
      mapped.price = mapped.price
        ? (Number.parseFloat(Number.parseFloat(option.price).toFixed(2)) || 0)
        : 0;
      mapped.fee_amount = mapped.fee_amount
        ? (Number.parseFloat(Number.parseFloat(option.fee_amount).toFixed(2)) || 0)
        : 0;
      delete mapped.included_services;
      return mapped;
    })
  );

  const saveAction = (type) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        const tourData = formatModel(response);
        reset(tourData);
        if (
          Array.isArray(tourData.media_contents)
          && tourData.media_contents.length > 0
        ) {
          setTourMediaContents([...tourData.media_contents.filter((current) => current.type === 'tour_image').sort((a, b) => a.order - b.order)]);
          setTourPreviewImage(tourData.media_contents.find((current) => current.type === 'tour_preview_image'));
        }
        setState({ ...state, loading: false, model: tourData });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error, errorToShow: response?.error });
        reject(new Error(response.data.message));
      };

      const prepareToUpdate = () => {
        const newData = getValues();
        delete newData.category_name;
        newData.attributes.purchase_options = mapPurchaseOptionsToUpdate(
          newData.attributes.purchase_options,
        );
        newData.media_contents = tourMediaContents;
        return newData;
      };
      const tourService = new TourService();
      let formData = null;
      switch (type) {
        case 'publish':
          if (tourMediaContents.length >= 7) {
            formData = prepareToUpdate();
            const formDataToPublish = { ...formData };
            formDataToPublish.published = !formData.published;
            tourService.updateItem(id, formDataToPublish, okEditCallback, koEditCallback);
          } else {
            setNotificationsModal({
              text: 'Per poter pubblicare il tour, si devono caricare 7 immagini della galleria Tour.',
              show: true,
              tabTarget: 'gallery-tab',
            });
            reject();
          }
          break;
        case 'publishNow':
          if (tourMediaContents.length >= 7) {
            vercelService.deploy()
              .then(() => {
                resolve({ message: 'Pubblicazione avvenuta con successo!' });
              })
              .catch((err) => {
                reject(new Error(err.data.message));
              });
          } else {
            setNotificationsModal({
              text: 'Per poter pubblicare il tour, si devono caricare 7 immagini della galleria Tour.',
              show: true,
              tabTarget: 'gallery-tab',
            });
            reject();
          }
          break;
        case 'save':
          formData = prepareToUpdate();
          tourService.updateItem(id, formData, okEditCallback, koEditCallback);
          break;
        default:
          formData = prepareToUpdate();
          tourService.updateItem(id, formData, okEditCallback, koEditCallback);
      }
    });

    toast.promise(savePromise, {
      loading: 'Attendere, operazione in corso...',
      // eslint-disable-next-line arrow-body-style
      success: (data) => { return (data?.message || 'Operazione eseguita con successo!'); },
      // eslint-disable-next-line arrow-body-style
      error: (err) => { return err ? err.toString() : 'Ops, si è verificato un errore!'; },
    }, {
      success: {
        duration: 5000,
      },
      error: {
        duration: 5000,
      },
    });
  };

  const getBasePrice = (purchaseOptions) => {
    if (purchaseOptions.length <= 0) return 0;
    if (purchaseOptions.length === 1) return purchaseOptions[0].price;
    const lowerPricePackage = purchaseOptions.reduce((prev, curr) => {
      const prevPrice = parseInt(prev.price, 10);
      const currPrice = parseInt(curr.price, 10);
      if (prevPrice <= currPrice) return prev; return curr;
    });
    return parseInt(lowerPricePackage.price, 10);
  };

  const insertPackage = (data, formProps) => {
    const newModel = { ...getValues() };
    const formatData = { ...data };
    delete formatData.id;
    if (!newModel.attributes) newModel.attributes = { purchase_options: [] };
    if (!newModel.attributes.purchase_options) newModel.attributes.purchase_options = [];
    newModel.attributes.purchase_options.push(formatData);
    newModel.base_price = getBasePrice(newModel.attributes.purchase_options);
    setValue('base_price', newModel.base_price);
    setValue('attributes', newModel.attributes);
    setState({ ...state, model: newModel });
    formProps.closeModal();
  };

  const editPackage = (data, formProps) => {
    const newModel = { ...getValues() };

    if (typeof formProps.target.data.idx === 'number') {
      const formatData = { ...data };
      newModel.attributes.purchase_options[formProps.target.data.idx] = (formatData);
      newModel.base_price = getBasePrice(newModel.attributes.purchase_options);
      setValue('base_price', newModel.base_price);
      setValue('attributes', newModel.attributes);
      setState({ ...state, model: newModel });
    }
    formProps.closeModal();
  };

  const deletePackage = (data) => {
    const newModel = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      newModel.attributes.purchase_options.splice(data.idx, 1);
      newModel.base_price = getBasePrice(newModel.attributes.purchase_options);
      setValue('base_price', newModel.base_price);
      setValue('attributes', newModel.attributes);
      setState({ ...state, model: newModel });
    }
  };

  const updateTimeTable = (timeTableData) => {
    const newModel = { ...getValues() };
    newModel.attributes.timeTable = { ...timeTableData };
    setState({ ...state, model: newModel });
  };

  const updateToursSelections = (field, selectionData) => {
    const newModel = { ...getValues() };
    newModel.attributes[field] = [...selectionData];
    setState({ ...state, model: newModel });
  };

  const updateTourLanguages = (tourLanguagesData) => {
    const newModel = { ...getValues() };
    newModel.attributes.languages = tourLanguagesData;
    setState({ ...state, model: newModel });
  };

  const updateTourTastingTime = (tourTastingTimesData) => {
    const newModel = { ...getValues() };
    newModel.attributes.tastingTimes = [...tourTastingTimesData];
    setState({ ...state, model: newModel });
  };

  const updateClosingDays = (closingDaysData) => {
    const newModel = { ...getValues() };
    newModel.attributes.closingDays = [...closingDaysData];
    setState({ ...state, model: newModel });
  };

  const handleChangePreviewImage = (response) => {
    if (response.job === 'delete') return setTourPreviewImage(null);
    return setTourPreviewImage(response.response.data);
  };

  const changeCurrentTab = (tabIndex) => {
    if (tabIndex !== state.currentActiveTab) setState({ ...state, currentActiveTab: tabIndex });
  };

  const onCloseNotificationsModal = () => {
    setNotificationsModal({
      ...notificationsModal,
      show: false,
    });
    setState({ ...state, currentActiveTab: notificationsModal.tabTarget });
  };

  const onChangeCoordinateValue = (e, field) => {
    // Allow only numeric values, comma and dot
    const sanitizedValue = e.target.value.replace(/[^0-9,.]/g, '');
    field.onChange(sanitizedValue);
  };

  const renderNotificationsModal = () => (
    <CModal backdrop="static" visible={notificationsModal.show}>
      <CModalHeader closeButton={false}>
        <CModalTitle>Avviso</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {notificationsModal.text}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => onCloseNotificationsModal()}
        >
          Chiudi
        </CButton>
      </CModalFooter>
    </CModal>
  );

  if (state.loading === true) return <AppLoadingSpinner />;

  if (state.error) return <p>NO DATA</p>;

  return (
    <>
      <CForm>
        <AppDetail
          sectionId="products"
          previewPage={previewPage}
          isContentPublished={state?.model?.published}
          saveAction={saveAction}
          name={getValues('name')}
          urlFriendlyName={getValues('url_friendly_name')}
          currentActiveTab={state.currentActiveTab}
          onTabChange={changeCurrentTab}
          tabsHeaders={[
            {
              index: 'package-tab',
              label: 'PACCHETTI TOUR',
            },
            {
              index: 'datetime-tab',
              label: 'ORARI E GIORNI',
            },
            {
              index: 'main-tab',
              label: 'NOME E DESCRIZIONE TOUR',
            },
            {
              index: 'coordinate-tab',
              label: 'COORDINATE TOUR',
            },
            {
              index: 'cover-tab',
              label: 'FOTO COPERTINA',
            },
            {
              index: 'gallery-tab',
              label: 'FOTO GALLERIA',
            },
            {
              index: 'tasting-tab',
              label: 'DEGUSTAZIONI',
            },
            {
              index: 'language-tab',
              label: 'LINGUA TOUR',
            },
            {
              index: 'web-tab',
              label: 'META DATI SEO',
            },
          ]}
          tabsContents={[
            {
              index: 'package-tab',
              content: (
                <CRow>
                  <CCol md={12}>
                    <h4>I tuoi Pacchetti Esperienza</h4>
                    <p>
                      Puoi creare un massimo di 3 pacchetti che
                      saranno visibili nella tua pagina di presentazione
                      del tour, facendo clic sull&apos;icona “matita” puoi
                      modificare il dettaglio dei servizi offerti per ogni
                      pacchetto, facendo click sul “cestino” potrai
                      cancellare il pacchetto.
                    </p>
                    <hr className="mb-4" />
                    <AppMultiData
                      buttonAddString="Aggiungi Nuovo Pacchetto"
                      title="Pacchetti"
                      item="Pacchetto"
                      modalSize="xl"
                      formId="pacchetto"
                      userGroup={userGroup}
                      createFormComponent={(formProps) => PackageForm({
                        formId: 'create_pacchetto',
                        submit: (data) => insertPackage(data, formProps),
                        parentProps: {
                          show: formProps.show,
                        },
                        userGroup,
                      })}
                      editFormComponent={(formProps) => PackageForm({
                        formId: 'edit_pacchetto',
                        submit: (data) => editPackage(data, formProps),
                        parentProps: {
                          show: formProps.show,
                          target: formProps.target,
                        },
                        userGroup,
                      })}
                      deleteFunction={(deleteData) => deletePackage(deleteData)}
                      columns={[
                        { index: 'name_tag', type: 'select' },
                        { index: 'price', type: 'currency' },
                        { index: 'price_type_tag', type: 'select' },
                      ]}
                      data={state?.model?.attributes?.purchase_options || null}
                      maxData={3}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'cover-tab',
              content: (
                <CRow>
                  <CCol>
                    <ImageWithPreview
                      contentId={id}
                      userGroup={userGroup}
                      contentType="tour_preview_image"
                      Service={TourService}
                      title="Copertina del tour"
                      data={tourPreviewImage}
                      onUpdate={(data) => handleChangePreviewImage(data)}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'gallery-tab',
              content: (
                <CRow>
                  <CCol>
                    <Gallery
                      contentId={id}
                      contentType="tour_image"
                      Service={TourService}
                      userGroup={userGroup}
                      title="Galleria del tour"
                      data={tourMediaContents}
                      onUpdate={(imagesArray) => setTourMediaContents(imagesArray)}
                      changeTitle
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'web-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Inserisci i Meta Dati SEO</h4>
                    <p>
                      Inserisci il Titolo, Keywords e la Meta
                      Descrizione.
                    </p>
                    <hr className="mb-4" />
                    <CRow className="g-3 mb-4">
                      <CCol md={12}>
                        <Controller
                          name="meta_title"
                          control={control}
                          render={({ field }) => <CFormInput label="Titolo" placeholder="Inserisce titolo" id="tour-meta_title" {...field} />}
                        />
                      </CCol>
                      <CCol md={12}>
                        <Controller
                          name="meta_keywords"
                          control={control}
                          render={({ field }) => (
                            <CFormInput
                              label={(
                                <>
                                  Keywords
                                  <br />
                                  <small>
                                    Per inserire più Keyword aggiungere
                                    tra le varie parole una “,” virgola
                                  </small>
                                </>
                              )}
                              placeholder="Inserisce le parole separate da virgole"
                              id="tour-meta_keywords"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                      <CCol md={12}>
                        <Controller
                          name="meta_description"
                          control={control}
                          render={({ field }) => <CFormTextarea maxLength={160} label="Meta descrizione" placeholder="Inserisce qui la meta descrizione" id="tour-meta_description" rows="3" {...field} />}
                        />
                        <small>Non superare i 160 caratteri</small>
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'main-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Contenuti testuali</h4>
                    <p>
                      Inserisci i testi per descrivere al meglio il tuo tour
                    </p>
                    <hr className="mb-4" />
                    <CRow className="g-3 mb-4">
                      <CCol md={12}>
                        <Controller
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <CFormTextarea
                              maxLength={165}
                              label={
                                (
                                  <>
                                    <span className="fs-5">Nome tour</span>
                                    <br />
                                    <small>
                                      Qui puoi modificare il nome del tour
                                    </small>
                                  </>
                                  )
                                }
                              id="tour-abstract"
                              rows="3"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                      <CCol md={12}>
                        <Controller
                          name="abstract"
                          control={control}
                          render={({ field }) => (
                            <>
                              <CFormTextarea maxLength={165} label={<span className="fs-5">Descrizione breve del tour</span>} id="tour-abstract" rows="3" {...field} />
                              <p className="text-align-end"><small>Max. 165 caratteri</small></p>
                            </>
                          )}
                        />
                      </CCol>
                      <CCol md={12}>
                        <Controller
                          name="description"
                          control={control}
                          render={({ field }) => (
                            <>
                              <CFormTextarea maxLength={1150} label={<span className="fs-5">Descrizione completa del tour</span>} id="tour-description" rows="5" {...field} />
                              <p className="text-align-end"><small>Max. 1150 caratteri</small></p>
                            </>
                          )}
                        />
                      </CCol>
                      <CCol md={12}>
                        <Controller
                          name="tour_warnings"
                          control={control}
                          render={({ field }) => (
                            <>
                              <CFormTextarea
                                maxLength={180}
                                label={(
                                  <>
                                    <span className="fs-5">Avvertenze</span>
                                    <br />
                                    <small>
                                      Eventuali informazioni
                                      aggiuntive che vuoi comunicare ai visitatori della tua
                                      scheda presentazione del tour
                                    </small>
                                  </>
                                )}
                                id="tour-warnings"
                                rows="5"
                                {...field}
                              />
                              <p className="text-align-end"><small>Max. 180 caratteri</small></p>
                            </>
                          )}
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'coordinate-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Coordinate del Tour</h4>
                    <p>
                      Qui puoi inserire o modificare le coordinate del tuo tour in modo che
                      sia visibile sulla mappa del sito Vinbacco.
                      <br />
                      Inserisci le coordinate in formato decimale, esempio:&nbsp;
                      <b>45,123456</b>
                    </p>
                    <hr className="mb-4" />
                    <CRow className="g-3 mb-4">
                      <CCol md={6}>
                        <Controller
                          name="attributes.coords.latitude"
                          control={control}
                          render={({ field }) => <CFormInput label="Latitudine" placeholder="Inserisci la latitudine" id="tour-latitude" {...field} onChange={(e) => onChangeCoordinateValue(e, field)} />}
                        />
                      </CCol>
                      <CCol md={6}>
                        <Controller
                          name="attributes.coords.longitude"
                          control={control}
                          render={({ field }) => <CFormInput label="Longitudine" placeholder="Inserisci la longitudine" id="tour-longitude" {...field} onChange={(e) => onChangeCoordinateValue(e, field)} />}
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'language-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Lingue del Tour</h4>
                    <p>
                      Seleziona le lingue in cui puoi offrire il tour di
                      degustazione e visita in cantina.
                    </p>
                    <hr className="mb-4" />
                    <LanguagesCheckbox
                      data={state?.model?.attributes?.languages}
                      onChange={(value) => updateTourLanguages(value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'datetime-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Orari e Giorni del Tour</h4>
                    <p>
                      Seleziona gli orari in cui sei disponibile per offrire il
                      sevizio esperienza, ti ricordiamo che potrai
                      selezionare un massimo di 8 orari, facendo
                      riferimento ai giorni indicati.
                      <br />
                      Per segnalare un giorno di chiusura e mostrarlo
                      nella tua scheda di presentazione del tour, ti basta
                      selezionare la casella dove c&apos;è scritto “Chiuso”.
                    </p>
                    <hr className="mb-4" />
                    <TimeTable
                      data={state?.model?.attributes?.timeTable}
                      onChange={(value) => updateTimeTable(value)}
                      max={8}
                      description="Seleziona un massimo di 8 orari in cui sei disponibile per effettuare le prenotazioni {day}"
                    />
                    <hr />
                    <TimesCheckbox
                      maxSelection={8}
                      title="Orari di degustazione"
                      description="Seleziona gli orari in cui offri il tuo servizio di degustazione"
                      data={state?.model?.attributes?.tastingTimes}
                      onChange={(value) => updateTourTastingTime(value)}
                    />
                    <hr />
                    <DateRangePicker
                      title="Giorni di chiusura"
                      description="Seleziona i giorni in cui sei chiuso"
                      data={state?.model?.attributes?.closingDays || []}
                      onChange={(value) => updateClosingDays(value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'tasting-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Tipologia Degustazioni speciali</h4>
                    <p>
                      Seleziona eventuali degustazioni speciali che vuoi
                      offrire ai tuoi clienti.
                    </p>
                    <hr className="mb-4" />
                    <ServicesCheckbox
                      serviceType="tasting"
                      data={state?.model?.attributes?.tasting_kind}
                      onChange={(value) => updateToursSelections('tasting_kind', value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
          ]}
        />
      </CForm>
      {renderNotificationsModal()}
    </>
  );
}

export default ToursDetail;
