import ApiProxyService from './apiProxyService';

export default class PromoCodesService extends ApiProxyService {
  BASE_PATH = '/promo_codes';

  getList({
    paginate,
    page,
    order = 'name',
    sort = 'ASC',
    filters,
    okCallback,
    koCallback,
  }) {
    let queryParams = {
      paginate,
      page,
      order_by: order,
      sort_by: sort,
    };
    let searchFilters = {};
    if (filters) {
      searchFilters = { ...filters };
      if (filters.search && filters.search.length > 0) {
        searchFilters['??^name'] = filters.search;
        delete searchFilters.search;
      }
      if (filters.customFilter) {
        searchFilters[filters.customFilter.key] = filters.customFilter.value;
        delete searchFilters.customFilter;
      }
      queryParams = {
        ...queryParams,
        ...searchFilters,
      };
    }
    const path = `${this.BASE_PATH}`;
    super.getList(path, queryParams, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}`;
    super.getItem(path, okCallback, koCallback);
  }

  addItem(body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}`;
    super.addItem(path, body, okCallback, koCallback);
  }

  updateItem(id, body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}`;
    super.updateItem(path, body, okCallback, koCallback);
  }

  deleteItem(deleteInfo, okCallback, koCallback) {
    const deletePromisesArray = [];
    if (typeof deleteInfo === 'string' || typeof deleteInfo === 'number') {
      deletePromisesArray.push(
        new Promise((resolve, reject) => {
          super.deleteItem(
            `${this.BASE_PATH}/${deleteInfo}`,
            (res) => resolve(res),
            (err) => reject(err),
          );
        }),
      );
    } else if (Array.isArray(deleteInfo)) {
      deleteInfo.forEach((id) => {
        deletePromisesArray.push(
          new Promise((resolve, reject) => {
            super.deleteItem(
              `${this.BASE_PATH}/${id}`,
              (res) => resolve(res),
              (err) => reject(err),
            );
          }),
        );
      });
    }
    Promise.all(deletePromisesArray).then(okCallback).catch(koCallback);
  }
}
