/* eslint-disable */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/**
 * TODO:
 * Pulire array selezionati dopo la risposta del elimina, una volta sia implementato.
 */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import {
  CCol,
  CFormInput,
  CRow,
  CFormLabel,
  CCard,
  CCardBody,
  CCardHeader,
} from "@coreui/react";

import TourService from "src/services/api/TourService";
import ContactsService from "src/services/api/ContactsService";
import AppList from "src/components/ui/List/AppList";
import composeErrorFormType from "src/utils/composeErrorFormType";

// Geocoder
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

function ToursList() {
  const [tourCoordinates, setTourCoordinates] = useState({
    street: "",
    latitude: "",
    longitude: "",
  });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const productCategories = useSelector((state) => state.productCategories);
  const loadContacts = (filter) =>
    new Promise((resolve) => {
      const contactsService = new ContactsService();
      const okGetContacts = (response) => {
        let responseData = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          responseData = response.data.map((currentItem) => ({
            value: currentItem._id,
            label: currentItem.business_name,
          }));
        }
        resolve(responseData);
      };
      const koGetContacts = () => resolve([]);
      const filters = {};
      if (filter.length > 0) filters["??^business_name"] = filter;
      contactsService.getList({
        paginate: 5,
        page: 1,
        filters,
        okCallback: (res) => okGetContacts(res),
        koCallback: (err) => koGetContacts(err),
      });
    });

  const resetAddressFields = () => {
    setTourCoordinates({ latitude: "", longitude: "", street: "" });
    setValue("latitude", "");
    setValue("longitude", "");
    setValue("address", "");
  };

  const onAddressSelect = (place) => {
    if (!place || !place?.label) {
      resetAddressFields();
      return;
    }
    const address = place?.label;
    setValue("address", address);
    let latitude = "";
    let longitude = "";
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        latitude = lat.toString();
        longitude = lng.toString();
        setValue("latitude", latitude);
        setValue("longitude", longitude);
      });
    setTourCoordinates({ latitude, longitude, street: address });
    clearErrors(["address", "latitude", "longitude"]);
  };

  const buildColumnsFn = () => [
    {
      key: "name",
      label: "Nome",
      sortable: true,
      _props: { scope: "col" },
    },
    {
      key: "contact.business_name",
      label: "Cantina",
      sortable: true,
      _props: { scope: "col" },
    },
  ];

  const buildRowsFn = (item) => ({
    _id: item._id,
    name: item.name,
    "contact.business_name": item.business_name,
  });

  const mapListFn = (item) => ({
    _id: item._id,
    name: item.name,
    business_name: item.contact?.business_name || "",
  });

  const creationBodyFn = () => (
    <>
      <CRow md={{ cols: 2, gutter: 2 }}>
        <CCol md={6}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.name}
                feedback={
                  errors?.name ? composeErrorFormType(errors.name) : null
                }
                type="text"
                id="tour-name"
                label="Nome del tour"
                placeholder="es. Tour degli ulivi"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={6}>
          <Controller
            name="contact_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <CFormLabel htmlFor="new-tour-contact">Cantina</CFormLabel>
                <AsyncSelect
                  inputId="new-tour-contact"
                  isClearable
                  defaultOptions
                  loadOptions={loadContacts}
                  {...field}
                />
                {errors.contact_id ? (
                  <div className="invalid-feedback d-block">
                    {composeErrorFormType(errors.contact_id)}
                  </div>
                ) : null}
              </>
            )}
          />
        </CCol>
        <CCol md={6}>
          <Controller
            name="product_category_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <>
                <CFormLabel htmlFor="new-tour-category">Regione</CFormLabel>
                <Select
                  inputId="new-tour-category"
                  isClearable
                  defaultOptions
                  options={productCategories.data}
                  {...field}
                />
                {errors.product_category_id ? (
                  <div className="invalid-feedback d-block">
                    {composeErrorFormType(errors.product_category_id)}
                  </div>
                ) : null}
              </>
            )}
          />
        </CCol>
      </CRow>
      <CRow md={{ cols: 1, gutter: 2 }} className="mt-4">
        <CCard>
          <CCardHeader style={{ backgroundColor: "#fff" }}>
            <h5 style={{ margin: 0 }}>Coordinate del tour</h5>
            <p style={{ margin: 0, fontSize: "1rem" }}>
              Seleziona un indirizzo per ottenere le coordinate del tour
            </p>
          </CCardHeader>
          <CCardBody>
            <CRow md={{ cols: 3, gutter: 2 }}>
              <CCol md={6}>
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => {
                    return (
                      <>
                        <CFormLabel htmlFor="new-tour-address">
                          Indirizzo
                        </CFormLabel>
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS}
                          inputId="new-tour-address"
                          apiOptions={{ language: "it", region: "it" }}
                          autocompletionRequest={{
                            componentRestrictions: {
                              country: ["it"],
                            },
                          }}
                          debounce={500}
                          minLengthAutocomplete={3}
                          onLoadFailed={(error) => {
                            console.log("Could not inject Google script", error);
                          }}
                          selectProps={{
                            placeholder: "es. Via Roma, 1",
                            onChange: (value) => onAddressSelect(value),
                            isClearable: true,
                          }}
                        />
                        {errors.address ? (
                          <div className="invalid-feedback d-block">
                            {composeErrorFormType(
                              { type: "address" },
                              "Seleziona un indirizzo dal menu a tendina"
                            )}
                          </div>
                        ) : null}
                      </>
                    );
                  }}
                />
              </CCol>
              <CCol md={3}>
                <Controller
                  name="latitude"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <CFormInput
                      readOnly
                      invalid={!!errors.latitude}
                      value={tourCoordinates?.latitude || ""}
                      feedback={
                        errors?.latitude
                          ? composeErrorFormType(errors.latitude)
                          : null
                      }
                      type="text"
                      id="tour-latitude"
                      label="Latitudine"
                      placeholder="es. 45.123456"
                      {...field}
                    />
                  )}
                />
              </CCol>
              <CCol md={3}>
                <Controller
                  name="longitude"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <CFormInput
                      readOnly
                      invalid={!!errors.longitude}
                      feedback={
                        errors?.longitude
                          ? composeErrorFormType(errors.longitude)
                          : null
                      }
                      type="text"
                      id="tour-longitude"
                      label="Longitudine"
                      placeholder="es. 9.123456"
                      {...field}
                    />
                  )}
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CRow>
    </>
  );
  return (
      <section id="tour">
        <AppList
          sectionId="products"
          sectionTitle="Lista Tour"
          SectionServiceClass={TourService}
          sectionPath="/tours"
          mapListFn={mapListFn}
          buildColumnsFn={buildColumnsFn}
          buildRowsFn={buildRowsFn}
          creationTitle="Creare un nuovo Tour"
          creationBodyFn={() => creationBodyFn()}
          evalCreation={handleSubmit}
          clearCreationModel={() => reset({})}
          isExportable
        />
      </section>
  );
}
export default ToursList;
