/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CButton,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CCol,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CAlert,
} from '@coreui/react';

import buttonActionTypes from 'src/utils/buttonActionTypes';

const SaveButtons = ({ saveAction, resetAction }) => (
  <CRow className="mb-4 justify-content-end">
    <CCol xs="auto">
      <CButton color="primary" onClick={() => saveAction()}>Salva</CButton>
    </CCol>
    <CCol xs="auto">
      <CButton color="secondary" onClick={() => resetAction()}>Annulla</CButton>
    </CCol>
  </CRow>
);

SaveButtons.propTypes = {
  saveAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
};

const Buttons = ({
  saveAction,
  urlFriendlyName,
  previewPage,
  isContentPublished,
  hideActions,
  handleSubmit,
  userGroup,
  sectionId,
  permissions,
}) => (
  ((permissions.edit === true || permissions.group === 'superadmin') && (
    <CRow className="mb-4 justify-content-end align-items-center">
      <CCol md={9} sm={12}>
        <CAlert color="primary" className="m-0">
          NB. Dopo aver effettuato le modifiche delle singole sezioni,
          ti ricordiamo di fare click su “Salva e Aggiorna”.
          {/* Una volta terminate le modifiche generali fai click su “Scegli Azione” e
          fai click su “Pubblica ora” per confermare le modifiche effettuate. */}
        </CAlert>
      </CCol>
      <CCol md="auto" sm="auto" xs="auto" className="ms-auto mt-4">
        <CDropdown direction="center">
          <CDropdownToggle color="primary"><span className="ps-2 pe-2">Scegli azione</span></CDropdownToggle>
          <CDropdownMenu>
            {urlFriendlyName !== null && (
              <>
                <CDropdownItem className="cursor-pointer" onClick={() => previewPage(urlFriendlyName)}>Anteprima pagina</CDropdownItem>
                <CDropdownDivider />
              </>
            )}
            {!hideActions.includes(buttonActionTypes.SAVE) && (
              <>
                <CDropdownItem className="cursor-pointer" onClick={() => (handleSubmit(saveAction))}>Salva bozza</CDropdownItem>
                {sectionId !== 'contacts' && (
                  <CDropdownDivider />
                )}
              </>
            )}
            {!hideActions.includes(buttonActionTypes.PUBLISH) && (
              <>
                <CDropdownItem className="cursor-pointer" onClick={() => saveAction('publish')}>{isContentPublished === true ? 'Non visibile' : 'Visibile'}</CDropdownItem>
                {/* <CDropdownDivider /> */}
              </>
            )}
            {/* {!hideActions.includes(buttonActionTypes.PUBLISH_NOW) &&
            (userGroup === 'admin' || userGroup === 'superadmin') && (
              <CDropdownItem className="cursor-pointer" onClick={() =>
                saveAction('publishNow')}>Pubblica ora</CDropdownItem>
            )} */}
          </CDropdownMenu>
        </CDropdown>
      </CCol>
    </CRow>
  ))
);

Buttons.propTypes = {
  saveAction: PropTypes.func.isRequired,
  previewPage: PropTypes.func,
  urlFriendlyName: PropTypes.string,
  isContentPublished: PropTypes.bool,
  hideActions: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func,
  userGroup: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  permissions: PropTypes.shape({
    group: PropTypes.string,
    create: PropTypes.bool,
    edit: PropTypes.bool,
    delete: PropTypes.bool,
  }).isRequired,
};

Buttons.defaultProps = {
  urlFriendlyName: null,
  previewPage: () => null,
  isContentPublished: false,
  hideActions: [],
  handleSubmit: (f) => { f(); },
  sectionId: null,
};

const AppDetail = (props) => {
  // const userGroup = useSelector((state) => state.user.value.user_group);
  const userData = useSelector((state) => state.user.value);
  const {
    name, isContentPublished, detail, urlFriendlyName, tabsContents, actions, saveActions,
    resetAction,
    saveAction, tabsHeaders, previewPage, currentActiveTab, onTabChange, hideActions,
    handleSubmit, sectionId,
  } = props;
  const permissions = {
    group: userData.user_group,
    create: userData.permissions.find((current) => current === `${sectionId}_create`) !== undefined,
    edit: userData.permissions.find((current) => current === `${sectionId}_edit`) !== undefined,
    delete: userData.permissions.find((current) => current === `${sectionId}_delete`) !== undefined,
  };
  const [activeTab, setActiveTab] = useState(tabsHeaders[0] || null);

  const handleSave = (params) => {
    const { action } = params;
    saveAction(action);
  };

  const handleActions = (type) => {
    switch (type) {
      case 'publishNow':
        saveAction('publishNow');
        return;
      case 'publish':
        saveAction('publish');
        break; // deploy
      case 'save':
        saveAction('save');
        break;
      default:
        saveAction('save');
    }
  };

  useEffect(() => {
    if (activeTab.index !== currentActiveTab) {
      const newActiveTab = (tabsHeaders.find((tabData) => tabData.index === currentActiveTab));
      if (newActiveTab) setActiveTab(newActiveTab);
    }
  }, [currentActiveTab]);

  const handleTabChange = (tab) => {
    onTabChange(tab.index);
    setActiveTab(tab);
  };

  return (
    <div style={{ marginBottom: '4em' }}>
      <CCard>
        <CCardHeader>
          <CRow className="mb-4">
            <CCol>
              <h2>{name}</h2>
              {detail && detail.length > 0 && (
                <h6>{detail}</h6>
              )}
            </CCol>
          </CRow>
          {actions === true && (
            <Buttons
              sectionId={sectionId}
              permissions={permissions}
              hideActions={hideActions}
              urlFriendlyName={urlFriendlyName}
              saveAction={handleActions}
              previewPage={previewPage}
              isContentPublished={isContentPublished}
              handleSubmit={handleSubmit}
              userGroup={userData.user_group}
            />
          )}
          {saveActions === true && (
            <SaveButtons
              saveAction={saveAction}
              resetAction={resetAction}
            />
          )}
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol className="d-lg-block d-md-none d-sm-none d-xs-none">
              <CNav variant="pills" role="tablist" className="mb-4">
                {tabsHeaders.map((currentTab, tabIndex) => (
                  <CNavItem key={`app-detail-tab-header-${tabIndex}_${currentTab}`}>
                    <CNavLink
                      className="cursor-pointer nav-pill-border"
                      active={activeTab.index === currentTab.index}
                      onClick={() => handleTabChange(currentTab)}
                    >
                      {currentTab.label}
                    </CNavLink>
                  </CNavItem>
                ))}
              </CNav>
            </CCol>
            <CCol className="d-lg-none d-md-block d-sm-block d-xs-block">
              <CDropdown direction="center" className="w-100 mb-4">
                <CDropdownToggle color="primary"><span className="ps-2 pe-2">{activeTab.label}</span></CDropdownToggle>
                <CDropdownMenu className="w-100">
                  {tabsHeaders.map((currentTab, tabIndex) => (
                    <React.Fragment key={`app-detail-tab-header-dropdown-${tabIndex}_${currentTab}`}>
                      <CDropdownItem
                        className="cursor-pointer text-align-center"
                        onClick={() => handleTabChange(currentTab)}
                      >
                        {currentTab.label}
                      </CDropdownItem>
                      {tabIndex < (tabsHeaders.length - 1) && <CDropdownDivider />}
                    </React.Fragment>
                  ))}
                </CDropdownMenu>
              </CDropdown>
            </CCol>
          </CRow>
          <CTabContent>
            {tabsContents.map((currentTab, tabIndex) => (
              <CTabPane
                key={`app-detail-tab-content-${tabIndex}_${currentTab}`}
                className="p-3"
                role="tabpanel"
                aria-labelledby="main-tab"
                visible={activeTab.index === currentTab.index}
              >
                {currentTab.content}
              </CTabPane>
            ))}
          </CTabContent>
        </CCardBody>
        {saveActions === true && (
          <CCardFooter>
            <SaveButtons
              saveAction={saveAction}
              resetAction={resetAction}
            />
          </CCardFooter>
        )}
        {actions === true && (permissions.edit === true || userData.user_group === 'admin') && (
          <CCardFooter>
            <CRow className="mt-4 mb-4 justify-content-end align-items-center">
              <CCol md={9} sm={12}>
                <CAlert color="primary" className="m-0 alert-primary-light">
                  NB. Dopo aver effettuato le modifiche delle singole sezioni,
                  ti ricordiamo di fare click su “Salva e Aggiorna”.
                  {/* Una volta terminate le modifiche generali fai click su “Scegli Azione”
                  e fai click su “Pubblica ora” per confermare le modifiche effettuate. */}
                </CAlert>
              </CCol>
              <CCol md="auto" sm="auto" xs="auto" className="ms-auto mt-4">
                <CButton color="success" className="cursor-pointer float-right color-white" onClick={() => handleSubmit(saveAction)}>Salva e Aggiorna</CButton>
              </CCol>
            </CRow>
          </CCardFooter>
        )}
      </CCard>
    </div>
  );
};

AppDetail.propTypes = {
  name: PropTypes.string,
  detail: PropTypes.string,
  urlFriendlyName: PropTypes.string,
  actions: PropTypes.bool,
  saveActions: PropTypes.bool,
  resetAction: PropTypes.func,
  saveAction: PropTypes.func,
  previewPage: PropTypes.func,
  tabsHeaders: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  tabsContents: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.string,
    content: PropTypes.element,
  })).isRequired,
  currentActiveTab: PropTypes.string,
  onTabChange: PropTypes.func,
  isContentPublished: PropTypes.bool,
  hideActions: PropTypes.arrayOf(PropTypes.string),
  handleSubmit: PropTypes.func,
  sectionId: PropTypes.string,
};

AppDetail.defaultProps = {
  name: null,
  detail: null,
  actions: true,
  saveActions: false,
  saveAction: () => null,
  resetAction: () => null,
  previewPage: () => null,
  urlFriendlyName: null,
  currentActiveTab: '',
  onTabChange: () => null,
  isContentPublished: false,
  hideActions: [],
  handleSubmit: (f) => { f(); },
  sectionId: null,
};

export default AppDetail;
