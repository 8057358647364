/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CButton,
  CCol,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
} from '@coreui/react';

const Buttons = ({
  saveAction, resetAction, downloadAction, sendAction,
}) => (
  <CRow className="mb-4 mt-2 justify-content-end">
    {sendAction && (
      <CCol xs="auto">
        <CButton color="info" onClick={() => sendAction()}>Invia ora</CButton>
      </CCol>
    )}
    {downloadAction && (
      <CCol xs="auto">
        <CButton color="success" onClick={() => downloadAction()}>Download</CButton>
      </CCol>
    )}
    <CCol xs="auto">
      <CButton color="primary" onClick={() => saveAction()}>Salva</CButton>
    </CCol>
    <CCol xs="auto">
      <CButton color="secondary" onClick={() => resetAction()}>Annulla</CButton>
    </CCol>
  </CRow>
);

Buttons.propTypes = {
  saveAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func,
  sendAction: PropTypes.func,
};

Buttons.defaultProps = {
  downloadAction: null,
  sendAction: null,
};

const AppBaseDetail = ({
  sectionId,
  type,
  name,
  saveAction,
  resetAction,
  downloadAction,
  sendAction,
  children,
}) => {
  const userData = useSelector((state) => state.user.value);

  const hasEditPermission = () => {
    const permissions = userData.permissions || [];
    const userGroup = userData.user_group || '';
    return (
      permissions.includes(`${sectionId}_edit`) ||
      userGroup === 'superadmin' ||
      sectionId === 'userProfile'
    );
  };

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <CRow className="mb-4">
          <CCol>
            <h2>Modifica {type}</h2>
            {typeof name === 'string' && name.length > 0 && <h4>{name}</h4>}
          </CCol>
        </CRow>
        {hasEditPermission() && (
          <Buttons
            saveAction={saveAction}
            resetAction={resetAction}
            downloadAction={downloadAction}
            sendAction={sendAction}
          />
        )}
      </CCardHeader>
      <CCardBody>
        <CRow>{children}</CRow>
      </CCardBody>
      <CCardFooter>
        {hasEditPermission() && (
          <Buttons
            saveAction={saveAction}
            resetAction={resetAction}
            downloadAction={downloadAction}
            sendAction={sendAction}
          />
        )}
      </CCardFooter>
    </CCard>
  );
};

AppBaseDetail.propTypes = {
  sectionId: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  saveAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  downloadAction: PropTypes.func,
  sendAction: PropTypes.func,
  children: PropTypes.element.isRequired,
};

AppBaseDetail.defaultProps = {
  name: null,
  sectionId: null,
  downloadAction: null,
  sendAction: null,
};

export default AppBaseDetail;
