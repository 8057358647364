/* eslint-disable */
// React
import React, { useState } from "react";
// Services
import AuthService from "src/services/api/authService";

// Lodash
import _isEmpty from "lodash/isEmpty";

// CoreUI
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormFeedback,
  CFormLabel,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";

// Toast
import toast, { Toaster } from "react-hot-toast";

// Assets
import Logo from "../../../assets/images/logo/logo_lg.svg";

function ResetPassword() {
  const [formData, setFormData] = useState({
    password: { value: "", error: null },
    confirmPassword: { value: "", error: null },
  });
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const authService = new AuthService();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get("code");

  const handleFormDataChange = (event) => {
    const newRegisterDataState = Object.assign({}, formData);
    newRegisterDataState[event.target.name].value = event.target.value;
    if (
      event.target.name === "confirmPassword" &&
      newRegisterDataState.password.value !==
        newRegisterDataState.confirmPassword.value &&
      event.target.value.length > 0
    ) {
      newRegisterDataState[event.target.name].error =
        "Le password non coincidono";
    } else {
      if (newRegisterDataState.password.value.length > 7)
        newRegisterDataState.password.error = null;
      newRegisterDataState.confirmPassword.error = null;
    }
    setFormData(newRegisterDataState);
  };

  const evalRegisterDataOnBlur = (event) => {
    const newRegisterDataState = Object.assign({}, formData);
    if (_isEmpty(newRegisterDataState[event.target.name].value)) {
      newRegisterDataState[event.target.name].error =
        "Questo campo non può essere vuoto";
    } else if (
      event.target.name === "password" &&
      newRegisterDataState[event.target.name].value.length < 8
    ) {
      newRegisterDataState[event.target.name].error =
        "La password deve contenere almeno 8 caratteri";
    }
    setFormData(newRegisterDataState);
  };

  const checkFormEnabled = () => {
    const newRegisterDataState = Object.assign({}, formData);
    return (
      !_isEmpty(newRegisterDataState.password.value) &&
      !_isEmpty(newRegisterDataState.confirmPassword.value) &&
      newRegisterDataState.password.value.length > 7 &&
      newRegisterDataState.password.value ===
        newRegisterDataState.confirmPassword.value
    );
  };

  const resetPassword = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (validated === false) setValidated(true);
    if (form.checkValidity() === true) {
      setLoading(true);
      const savePromise = new Promise((resolve, reject) => {
        const okCallback = () => {
          setLoading(false);
          setFormData({
            password: { value: "", error: null },
            confirmPassword: { value: "", error: null },
          });
          setValidated(false);
          resolve();
        };

        const koCallback = (error) => {
          setLoading(false);
          reject();
        };

        authService.renewPassword(
          code,
          formData.password.value,
          okCallback,
          koCallback
        );
      });

      toast.promise(
        savePromise,
        {
          loading: "Cambiando la password...",
          success: "Password cambiata con successo.",
          error: "Errore durante il cambio della password.",
        },
        {
          success: {
            duration: 10000,
          },
          error: {
            duration: 7500,
          },
        }
      );
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <Toaster position="top-right" reverseOrder={false} />
        <CRow className="justify-content-center">
          <CCol md={8} lg={5}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CImage className="login-logo" src={Logo} />
                  <h1>Cambia Password</h1>
                  <p className="text-medium-emphasis">
                    Inserisci la nuova password.
                  </p>
                  <CForm
                    noValidate
                    validated={validated}
                    onSubmit={resetPassword}
                  >
                    <CFormLabel htmlFor="password">Password</CFormLabel>
                    <CInputGroup className="mb-2">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password.value}
                        feedbackInvalid={formData.password.error}
                        onChange={(event) => {
                          handleFormDataChange(event);
                        }}
                        onBlur={(event) => {
                          evalRegisterDataOnBlur(event);
                        }}
                        required
                        tooltipFeedback
                      />
                    </CInputGroup>
                    {(formData.password.error !== undefined ||
                      formData.password.error !== null) && (
                      <CFormFeedback
                        className="mb-2"
                        style={{ color: "#c62828" }}
                      >
                        {formData.password.error}
                      </CFormFeedback>
                    )}
                    <CFormLabel className="mt-2" htmlFor="confirmPassword">
                      Conferma Password
                    </CFormLabel>
                    <CInputGroup className="mb-2">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Ripeti Password"
                        value={formData.confirmPassword.value}
                        feedbackInvalid={formData.confirmPassword.error}
                        onChange={(event) => {
                          handleFormDataChange(event);
                        }}
                        onBlur={(event) => {
                          evalRegisterDataOnBlur(event);
                        }}
                        required
                        tooltipFeedback
                      />
                    </CInputGroup>
                    {(formData.confirmPassword.error !== undefined ||
                      formData.confirmPassword.error !== null) && (
                      <CFormFeedback
                        className="mb-2"
                        style={{ color: "#c62828" }}
                      >
                        {formData.confirmPassword.error}
                      </CFormFeedback>
                    )}
                    <CRow>
                      {loading === true && (
                        <CCol xs={12} className="text-center" color="primary">
                          <CSpinner />
                        </CCol>
                      )}
                      {loading === false && (
                        <CCol xs={12}>
                          <CButton
                            type="submit"
                            disabled={loading === true || !checkFormEnabled()}
                            color="primary"
                            className="px-4 w-100 mt-3 mb-3"
                          >
                            Cambia Password
                          </CButton>
                        </CCol>
                      )}
                    </CRow>
                    <CCol xs={12} className="text-center">
                      <CButton
                        color="link"
                        className="px-0"
                        onClick={() => {
                          window.location.href = "/#/login";
                        }}
                      >
                        Torna al Login
                      </CButton>
                    </CCol>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default ResetPassword;
