/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CCard,
  CCardHeader,
  CCardBody,
  CFormTextarea,
} from '@coreui/react';

// SERVICES
import VouchersService from 'src/services/api/VouchersService';

// COMPONENTS
import AppBaseDetail from 'src/components/ui/Detail/AppBaseDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';

// UTILS
import utils from 'src/services/api/utils/utils';
import composeErrorFormType from 'src/utils/composeErrorFormType';

const VouchersDetail = () => {
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [state, setState] = useState({
    loading: true,
    model: null,
    processingBooking: false,
  });

  const vouchersService = new VouchersService();

  const formatModel = (response) => {
    const newModel = { ...response.data };
    setValue('voucher_code', newModel.code || '');
    setValue('recipient_name', newModel.variants?.addresse || '');
    setValue('recipient_email', newModel.variants?.email || '');
    setValue('voucher_price', utils.formatCurrency({ number: newModel.variants?.price }));
    setValue('voucher_balance', utils.formatCurrency({ number: newModel.remaining_amount }));
    setValue('recipient_message', newModel.variants?.message || '');
    setValue('expiration_date', newModel.expiring_at ? format(new Date(newModel.expiring_at), 'yyyy-MM-dd') : '-');
    setValue('send_dt_day', newModel.variants?.send_dt ? format(new Date(newModel.variants?.send_dt), 'yyyy-MM-dd') : '');
    setValue('send_dt_time', newModel.variants?.send_dt ? format(new Date(newModel.variants?.send_dt), 'HH:mm') : '');
    return newModel;
  };

  const onSubmit = (data, sendNow = false) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ ...state, loading: false, error: response?.error });
        reject(new Error(response?.data?.message));
      };

      const body = { ...state.model };
      body.variants.email = data.recipient_email;
      body.variants.addresse = data.recipient_name;
      body.variants.message = data.recipient_message;
      body.expiring_at = new Date(data.expiration_date).toISOString();
      // date example: send_dt_day: "2023-11-17", send_dt_time: "11:37"
      // body.variants.send_dt = !sendNow
      // ? new Date(`${data.send_dt_day} ${data.send_dt_time}`).toISOString()
      // : new Date(Date.now() + 30000).toISOString(); // if sendNow is true, send_dt is current time plus 30 seconds

      // Check if sendNow is false and both send_dt_day and send_dt_time are provided
      if (!sendNow && data.send_dt_day && data.send_dt_time) {
        body.variants.send_dt = new Date(`${data.send_dt_day} ${data.send_dt_time}`).toISOString();
      } else if (sendNow) {
      // If sendNow is true set send_dt to current time plus 30 seconds
        body.variants.send_dt = new Date(Date.now() + 30000).toISOString();
      }
      // If sendNow is false and one of the two fields (send_dt_day or send_dt_time) is missing, send_dt is not sent to the server. This is the case when a voucher is created directly from the back office.
      body.variants.date = data.send_dt_day;
      body.variants.time = data.send_dt_time;
      vouchersService.updateItem(state.model['_id'], body, okEditCallback, koEditCallback);
    });

    toast.promise(
      savePromise,
      {
        loading: 'Attendere, salvando le modifiche...',
        success: sendNow
          ? 'Voucher inviato con successo!'
          : 'Voucher salvato con successo!',
        error: (err) => {
          if (err?.message === 'Send date or time in the past!') {
            return 'Errore: la data e/o l\'ora di invio del voucher sono nel passato!';
          }
          return `Ops, si è verificato un errore! ${err?.message || ''}`;
        },
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      },
    );
  };

  useEffect(() => {
    if (id !== null && typeof id !== 'undefined') {
      const okGetCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      vouchersService.getItem(id, okGetCallback, koGetCallback);
    }
  }, [id]);

  const handleReset = () => {
    const resetModel = {
      voucher_code: state.model.code || '',
      recipient_name: state.model.variants.addresse || '',
      recipient_email: state.model.variants.email || '',
      voucher_price: utils.formatCurrency({ number: state.model.variants.price }),
      voucher_balance: utils.formatCurrency({ number: state.model.remaining_amount }),
      recipient_message: state.model.variants.message || '',
      expiration_date: format(new Date(state.model.expiring_at), 'dd/MM/yyyy'),
      send_dt: format(new Date(state.model.variants.send_dt), 'dd/MM/yyyy'),
    };

    reset({ ...resetModel });
  };

  const handleDownload = () => {
    const okGetPdf = (response) => {
      toast.success('Download del voucher avvenuto con successo!', {
        duration: 5000,
        position: 'top-center',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `voucher_${state.model?.variants?.addresse?.replace(/\s+/g, '_')?.toLowerCase() || ''}.pdf`;
      link.click();
      link.remove();
    };

    const koGetPdf = (error) => {
      toast.error('Si è verificato un errore durante il download del voucher!');
      throw new Error(error);
    };

    vouchersService.downloadVoucher(id, okGetPdf, koGetPdf);
  };

  if (state?.loading === true) return <AppLoadingSpinner />;

  return (
    <AppBaseDetail
      sectionId="vouchers"
      type="voucher"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
      downloadAction={handleDownload}
      sendAction={handleSubmit((data) => onSubmit(data, true))}
    >
      <CForm className="row g-3 mt-3" onSubmit={handleSubmit(onSubmit)}>
        <CRow className="mb-3">
          <CCol md={12} sm={12}>
            <Controller
              name="voucher_code"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <CFormInput
                  disabled
                  invalid={!!errors.voucher_code}
                  feedback={
                    errors?.voucher_code
                      ? composeErrorFormType(errors.voucher_code)
                      : null
                  }
                  type="text"
                  id="voucher_code"
                  label="Codice voucher"
                  placeholder="Codice voucher"
                  {...field}
                />
              )}
            />
          </CCol>
        </CRow>
        <CCol md={12} sm={12}>
          <CCard>
            <CCardHeader>Dettagli del buono regalo</CCardHeader>
            <CCardBody>
              <CRow>
                <CCol md={12} sm={12}>
                  <Controller
                    name="voucher_price"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        disabled
                        invalid={!!errors.voucher_price}
                        feedback={
                          errors?.voucher_price
                            ? composeErrorFormType(errors.voucher_price)
                            : null
                        }
                        type="text"
                        id="voucher_price"
                        label="Importo"
                        placeholder="Importo voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="voucher_balance"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        disabled
                        invalid={!!errors.voucher_balance}
                        feedback={
                          errors?.voucher_balance
                            ? composeErrorFormType(errors.voucher_balance)
                            : null
                        }
                        type="text"
                        id="voucher_balance"
                        label="Saldo attuale"
                        placeholder="Saldo attuale voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="recipient_email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.recipient_email}
                        feedback={
                          errors?.recipient_email
                            ? composeErrorFormType(
                              errors.recipient_email,
                              'Inserire un indirizzo email valido',
                            )
                            : null
                        }
                        type="email"
                        id="recipient_email"
                        label="Email del destinatario"
                        placeholder="Email del destinatario del voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="recipient_name"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.recipient_name}
                        feedback={
                          errors?.recipient_name
                            ? composeErrorFormType(errors.recipient_name)
                            : null
                        }
                        type="text"
                        id="recipient_name"
                        label="Nome del destinatario"
                        placeholder="Nome del destinatario del voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="recipient_message"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormTextarea
                        invalid={!!errors.recipient_message}
                        feedback={
                          errors?.recipient_message
                            ? composeErrorFormType(errors.recipient_message)
                            : null
                        }
                        type="text"
                        id="recipient_message"
                        label="Dedica"
                        placeholder="Dedica del voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={6} sm={6}>
                  <Controller
                    name="send_dt_day"
                    control={control}
                    rules={{
                      required: false,
                      // validate: (value) => {
                      //   const today = new Date();
                      //   today.setHours(0, 0, 0, 0);
                      //   const selectedDate = new Date(value);
                      //   return selectedDate >= today;
                      // },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.send_dt_day}
                        feedback={
                          errors?.send_dt_day
                            ? composeErrorFormType(errors.send_dt_day, 'Inserire una data futura')
                            : null
                        }
                        type="date"
                        id="send_dt_day"
                        label="Data di consegna del voucher"
                        placeholder="Inserisci la data di consegna"
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={6} sm={6}>
                  <Controller
                    name="send_dt_time"
                    control={control}
                    rules={{ required: false }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.send_dt_time}
                        feedback={
                          errors?.send_dt_time
                            ? composeErrorFormType(errors.send_dt_time)
                            : null
                        }
                        type="time"
                        id="send_dt_time"
                        label="Ora di consegna del voucher"
                        placeholder="Inserisci l'ora di consegna"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol md={12} sm={12}>
                  <Controller
                    name="expiration_date"
                    control={control}
                    rules={{
                      required: true,
                      validate: (value) => {
                        const today = new Date();
                        const selectedDate = new Date(value);
                        return selectedDate > today;
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => (
                      <CFormInput
                        invalid={!!errors.expiration_date}
                        feedback={
                          errors?.expiration_date
                            ? composeErrorFormType(errors.expiration_date, 'Inserire una data futura')
                            : null
                        }
                        type="date"
                        id="expiration_date"
                        label="Data di scadenza"
                        placeholder="Data di scadenza del voucher"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CForm>
    </AppBaseDetail>
  );
};

export default VouchersDetail;
