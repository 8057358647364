/* eslint-disable */
import React, {useState, useEffect, useRef} from "react";
import {
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle,
  CWidgetStatsA,
} from "@coreui/react";
import { getStyle } from "@coreui/utils";
import { CChartBar, CChartLine } from "@coreui/react-chartjs";
import CIcon from "@coreui/icons-react";
import { cilArrowBottom, cilArrowTop, cilOptions } from "@coreui/icons";

function WidgetChart({ selectedYear, color, value, percentage, title, data, chartBGColor, monthlyData, noRadius}) {


  const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'];



  return (
    <CWidgetStatsA
      className="mb-4"
      color={color || "primary"}
      value={
        <>
          {value}
        </>
      }
      title={`${title} ${selectedYear}`}
      action={
        <CDropdown alignment="end">
          <CDropdownToggle color="transparent" caret={false} className="p-0">
            <CIcon icon={cilOptions} className="text-high-emphasis-inverse" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem>Vai a lista clienti</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      }
      chart={
        <CChartLine
          className="mt-3"
          style={{ height: "70px" }}
          data={{
            labels: months,
            datasets: [
              {
                // label: "My First dataset",
                backgroundColor: chartBGColor || "transparent",
                borderColor: "rgba(255,255,255,.55)",
                pointBackgroundColor: "white",
                data: monthlyData,
                fill: true,
              },
            ],
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,
            scales: {
                x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
            },
            elements: {
              line: {
                borderWidth: 1,
                tension: 0.4,
              },
              point: {
                radius: noRadius ? 0 : 3,
                hitRadius: 10,
                hoverRadius: 4,
              },
            },
          }}
        />
      }
    />
  );
}

export default WidgetChart;
