import React from 'react';
import PropTypes from 'prop-types';
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CForm,
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
} from '@coreui/react';

function InvoicingExportModal({
  visible,
  onClose,
  onSubmit,
  // sectionService,
  title,
  description,
  fields,
  submitButtonText,
}) {
  return (
    <CModal
      backdrop="static"
      visible={visible}
      alignment="center"
    >
      <CModalHeader closeButton={false}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>
      <CForm id="invoicingExportForm" onSubmit={onSubmit}>
        <CModalBody>
          <CRow>
            {typeof description === 'string' ? <p>{description}</p> : description}
            {fields.map((field) => (
              <CCol key={field.id}>
                <CFormLabel htmlFor={field.id}>{field.label}</CFormLabel>
                <CFormInput
                  type={field.type}
                  id={field.id}
                  name={field.id}
                  required={field.required}
                  max={field.max}
                />
              </CCol>
            ))}
          </CRow>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            onClick={onClose}
          >
            Annulla
          </CButton>
          <CButton type="submit" form="invoicingExportForm" color="primary">
            {submitButtonText}
          </CButton>
        </CModalFooter>
      </CForm>
    </CModal>
  );
}

InvoicingExportModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // sectionService: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    max: PropTypes.string,
  })).isRequired,
  submitButtonText: PropTypes.string.isRequired,
};

export default InvoicingExportModal;
