/* eslint-disable quotes */

const regionOptions = [
  { value: "all_regions", label: "Tutta Italia" },
  { value: "64ff2993ccd50c33f5e9f2b7", label: "Abruzzo" },
  { value: "64ff12fbccd50c33f5e9f2b4", label: "Basilicata" },
  { value: "6560c432f187270014dce637", label: "Calabria" },
  { value: "64ff153bccd50c33f5e9f2b5", label: "Campania" },
  { value: "6355b2de988cb9d0f511ccd0", label: "Emilia-Romagna" },
  { value: "6355b2b4988cb9d0f511ccc8", label: "Friuli-Venezia Giulia" },
  { value: "6355b2d2988cb9d0f511ccce", label: "Lazio" },
  { value: "654e42e00f84a1001493c574", label: "Liguria" },
  { value: "6355b293988cb9d0f511ccc2", label: "Lombardia" },
  { value: "6355b2a9988cb9d0f511ccc6", label: "Marche" },
  { value: "6560c391f187270014dce635", label: "Molise" },
  { value: "6355b289988cb9d0f511ccc0", label: "Piemonte" },
  { value: "6355b25d988cb9d0f511ccb8", label: "Puglia" },
  { value: "63827c3c73ac800016511a2f", label: "Sardegna" },
  { value: "6355b2c8988cb9d0f511cccc", label: "Sicilia" },
  { value: "6355b272988cb9d0f511ccbc", label: "Toscana" },
  { value: "6355b2be988cb9d0f511ccca", label: "Trentino-Alto Adige" },
  { value: "6355b29e988cb9d0f511ccc4", label: "Umbria" },
  { value: "63cd53b4348b302c8d4e8a07", label: "Valle d'Aosta" },
  { value: "6355b27d988cb9d0f511ccbe", label: "Veneto" },
];

export default regionOptions;
