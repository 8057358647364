/* eslint-disable */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CRow } from "@coreui/react";
import { format, isSameDay } from "date-fns";
import { DayPicker } from "react-day-picker";
import { it } from "date-fns/locale";
import "react-day-picker/dist/style.css";

function DateRangePicker({ title, description, onChange, data }) {
  const [days, setDays] = useState(data?.map((day) => new Date(day)) || []);
  const currentDate = new Date();

  const selectedDatesFormatted = (
    <div className="d-flex flex-wrap gap-2">
      {days?.length > 0 &&
        days
          .slice() // copy array
          .filter((day) => day > currentDate || isSameDay(day, currentDate)) // remove past dates
          .sort((a, b) => a - b) // sort dates
          .map((day) => {
            return (
              <span
                key={day}
                className="p-1"
                style={{ border: "1px solid #9da5b1", borderRadius: "6px" }}
              >
                {format(new Date(day), "dd/MM/yyyy")}
              </span>
            );
          })}
    </div>
  );

  const footer =
    days && days.length > 0 ? (
      <>
        <h5>Giorni selezionati:</h5>
        {selectedDatesFormatted}
      </>
    ) : (
      <p>Nessun giorno selezionato</p>
    );

  const handleOnChange = (selectedDays) => {
    setDays(selectedDays);
    const formattedDates = selectedDays.map((day) => format(day, "yyyy-MM-dd"));
    onChange(formattedDates);
  };

  return (
    <>
      <CRow className="pb-4">
        <h4>{title}</h4>
        <p>{description}</p>
      </CRow>
      <CRow className="pb-4">
        <DayPicker
          locale={it}
          fromDate={new Date()}
          mode="multiple"
          min={0}
          selected={days}
          onSelect={handleOnChange}
          modifiersStyles={{
            selected: {
              backgroundColor: "#160836",
              color: "white",
              opacity: 1,
            },
          }}
        />
        {footer}
      </CRow>
    </>
  );
}

DateRangePicker.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

DateRangePicker.defaultProps = {
  title: "Closing days",
  description: "Select days",
  data: [],
};

export default DateRangePicker;
