import React from 'react';
import PropTypes from 'prop-types';
import { CCard, CCardBody, CRow } from '@coreui/react';
import { CChart } from '@coreui/react-chartjs';
import AppLoadingSpinner from '../AppLoadingSpinner';

function MonthlyBarChart({
  selectedYear, data, title, isLoading,
}) {
  return (
    <CCard className="mb-4">
      <CCardBody>
        <CRow className="my-4">
          <h4 className="card-title mb-2">{title}</h4>
          <div className="small text-medium-emphasis">{selectedYear}</div>
          {isLoading ? (
            <AppLoadingSpinner />
          ) : (
            <CChart type="bar" labels="mesi" data={data} />
          )}
        </CRow>
      </CCardBody>
    </CCard>
  );
}

MonthlyBarChart.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(PropTypes.number),
    })),
  }).isRequired,
  isLoading: PropTypes.bool,
};

MonthlyBarChart.defaultProps = {
  isLoading: false,
};

export default MonthlyBarChart;
