/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable arrow-body-style */

import React, { useState } from 'react';
import {
  CCol,
  CFormInput,
  CRow,
  CForm,
  CButton,
  CInputGroup,
  CFormLabel,
  CFormSelect,
} from '@coreui/react';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';

import VouchersService from 'src/services/api/VouchersService';
import AppList from 'src/components/ui/List/AppList';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import utils from 'src/services/api/utils/utils';

const filterOptions = [
  {
    label: 'Seleziona filtro',
    value: '',
  },
  {
    label: 'Codice voucher',
    value: 'code',
  },
  {
    label: 'Email mittente',
    value: 'sender_email',
  },
  {
    label: 'Email destinatario',
    value: 'recipient_email',
  },
  {
    label: 'Nome ordinante',
    value: 'name',
  },
  {
    label: 'Numero ordine',
    value: 'order_number',
  },
];

function VouchersList() {
  const [statusFilter, setStatusFilter] = useState('');
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const buildColumnsFn = () => [
    {
      key: 'voucher_code',
      label: 'Codice voucher acquistato',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'order_number',
      label: 'Ordine',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'addresse',
      label: 'Nome e cognome destinatario',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'sender',
      label: 'Nome e cognome mittente',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'voucher_balance',
      label: 'Saldo',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'purchase_date',
      label: 'Data di acquisto',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'expiration_date',
      label: 'Data di scadenza',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'recipient',
      label: 'Info Destinatario',
      sortable: false,
      _props: { scope: 'col' },
    },
  ];

  const buildRowsFn = (item) => {
    const orderNumber = (item.order && item.order.user?.name) ? `#${item.order_number} per ${item.order.user?.name}` : item.order_number ? `#${item.order_number}` : null;
    return (
      { // FIXME: Aggiungere azioni!
        _id: item._id,
        voucher_code: item.voucher_code,
        order_number: orderNumber || '-',
        voucher_balance: item.voucher_balance,
        purchase_date: item.purchase_date || item?.order_created_at,
        expiration_date: item.expiration_date,
        sender: item.sender,
        addresse: item.addresse,
        recipient: (
          <p className="m-0">
            {item.recipient.email}
            <br />
            <b>Data di consegna: </b>
            {item.recipient.delivery_date}
          </p>
        ),
      }
    );
  };

  const mapListFn = (item) => {
    const recipientData = {
      name: item?.variants?.addresse || null,
      email: item?.variants?.email || null,
      delivery_date: item?.variants?.send_dt ? format(new Date(item?.variants?.send_dt), 'dd/MM/yyyy HH:mm') : '-',
    };
    const orderNumber = item.order ? `${item.order.order_number}` : item.order_number ? `${item?.order_number}` : null;
    const purchaseDate = item.order?.created_at ? format(new Date(item.order?.created_at), 'dd/MM/yyyy') :
      item.order_created_at ? format(new Date(item.order_created_at), 'dd/MM/yyyy') : '-';

    return ({
      _id: item._id,
      voucher_code: item.code,
      order: item.order,
      order_number: orderNumber,
      voucher_balance: utils.formatCurrency({ number: item?.remaining_amount }),
      purchase_date: purchaseDate,
      expiration_date: item.expiring_at ? format(new Date(item.expiring_at), 'dd/MM/yyyy') : '-',
      recipient: { ...recipientData },
      sender: item.variants.sender,
      addresse: item.variants.addresse,
    });
  };

  const onCustomFilter = (tableData, applyFilters) => {
    const newData = JSON.parse(JSON.stringify(tableData));
    let statusFilterKeyWithClause = '';
    if (statusFilter === '1') { // attivi
      statusFilterKeyWithClause = ['expiring_at=false&gt_^remaining_amount', 0]; // not expired and with positive balance
    } else if (statusFilter === '2') { // scaduti
      statusFilterKeyWithClause = ['expiring_at', true];
    } else if (statusFilter === '3') { // utilizzati
      statusFilterKeyWithClause = ['^remaining_amount', 0];
    }
    newData.customFilter = {
      key: statusFilterKeyWithClause[0],
      value: statusFilterKeyWithClause[1],
    };
    return applyFilters(newData);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const StatusFilter = ({ tableData = [], applyFilters = () => {} }) => {
    return (
      <CRow className="align-items-end mb-4">
        <CCol md={12} lg={6}>
          <CForm onSubmit={(e) => { e.preventDefault(); onCustomFilter(tableData, applyFilters); }}>
            <CFormLabel htmlFor="customFilter">Filtro stato voucher</CFormLabel>
            <CInputGroup>
              <CFormSelect
                onChange={(e) => {
                  setStatusFilter(e.target.options[e.target.selectedIndex].value);
                }}
                aria-label="Filtro status"
                value={statusFilter}
                options={[
                  { label: 'Seleziona stato', value: '', selected: statusFilter === '' },
                  { label: 'Attivi', value: '1', selected: statusFilter === '2' },
                  { label: 'Scaduti', value: '2', selected: statusFilter === '3' },
                  { label: 'Utilizzati', value: '3', selected: statusFilter === '4' },
                ]}
              />
              <CButton disabled={tableData.data === null} type="submit" name="filter" color="primary" id="filter-button">Filtra</CButton>
              <CButton
                disabled={tableData?.data === null || tableData?.search?.length <= 0}
                type="button"
                name="reset"
                color="danger"
                id="filter-button-clear"
                onClick={() => { window.location.reload(); }}
              >
                Cancella
              </CButton>
            </CInputGroup>
          </CForm>
        </CCol>
      </CRow>
    );
  };

  const creationBodyFn = () => (
    <>
      <CRow md={{ cols: 2, gutter: 2 }}>
        <CCol md={3}>
          <Controller
            name="code"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.code}
                feedback={errors?.code ? composeErrorFormType(errors.code) : null}
                type="text"
                id="code"
                label="Codice"
                placeholder="Inserisci codice"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={3}>
          <Controller
            name="variants.addresse"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.variants?.addresse}
                feedback={errors.variants?.addresse
                  ? composeErrorFormType(errors.variants?.addresse)
                  : null}
                type="text"
                id="addresse"
                label="Nome e cognome destinatario"
                placeholder="Inserisci nome e cognome destinatario"
                {...field}
              />
            )}
          />
        </CCol>
      </CRow>
      <CRow />
      <CRow md={{ cols: 2, gutter: 2 }}>
        <CCol md={3}>
          <Controller
            name="variants.email"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.variants?.email}
                feedback={errors?.variants?.email
                  ? composeErrorFormType(errors.variants?.email)
                  : null}
                type="text"
                id="email"
                label="Email destinatario"
                placeholder="Inserisci email destinatario"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={3}>
          <Controller
            name="variants.sender"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.variants?.sender}
                feedback={errors?.variants?.sender
                  ? composeErrorFormType(errors.variants?.sender)
                  : null}
                type="text"
                id="sender"
                label="Nome e cognome mittente"
                placeholder="Inserisci nome e cognome mittente"
                {...field}
              />
            )}
          />
        </CCol>
      </CRow>
      <CRow md={{ cols: 2, gutter: 2 }}>
        <CCol md={3}>
          <Controller
            name="variants.price"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.variants?.price}
                feedback={errors?.variants?.price
                  ? composeErrorFormType(errors.variants?.price)
                  : null}
                type="text"
                id="price"
                label="Prezzo"
                placeholder="Inserisci prezzo"
                {...field}
              />
            )}
          />
        </CCol>
        <CCol md={3}>
          <Controller
            name="variants.message"
            control={control}
            rules={{ required: false }}
            defaultValue=""
            render={({ field }) => (
              <CFormInput
                invalid={!!errors.variants?.message}
                feedback={errors?.variants?.message
                  ? composeErrorFormType(errors.variants?.message)
                  : null}
                type="text"
                id="message"
                label="Dedica"
                placeholder="Inserisci dedica"
                {...field}
              />
            )}
          />
        </CCol>
      </CRow>
    </>
  );

  return (
    <section id="vouchers">
      <AppList
        initialSortOrder="desc"
        initialSortField="order.created_at"
        scrollable
        sectionId="vouchers"
        sectionTitle="Lista vouchers"
        SectionServiceClass={VouchersService}
        sectionPath="/vouchers"
        mapListFn={mapListFn}
        buildColumnsFn={buildColumnsFn}
        buildRowsFn={buildRowsFn}
        creationTitle="Nuovo voucher"
        creationBodyFn={() => creationBodyFn()}
        evalCreation={handleSubmit}
        clearCreationModel={() => reset({})}
        filterOptions={filterOptions}
        // isExportable
        CustomFilter={StatusFilter}
      />
    </section>
  );
}
export default VouchersList;
