import { configureStore } from '@reduxjs/toolkit';

import userReducer from './slices/user.slice';
import appReducer from './slices/app.slice';
import productCategoriesReducer from './slices/productCategories.slice';

const store = configureStore({
  reducer: {
    user: userReducer,
    app: appReducer,
    productCategories: productCategoriesReducer,
  },
});

export default store;
