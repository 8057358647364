/* eslint-disable */
// React
import React, { useState, useEffect } from "react";

// Libs
import { CCol, CRow, CTableDataCell, CTableRow } from "@coreui/react";

// Components
import YearSelector from "src/components/ui/Dashboard/YearSelector";
import WidgetsDropdown from "src/components/ui/Dashboard/Widgets/WidgetsDropdown";
import MonthlyBarChart from "src/components/ui/Dashboard/MonthlyBarChart";
import TopMonthlyChart from "src/components/ui/Dashboard/TopMonthlyChart";
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";

// utils
import utils from "src/services/api/utils/utils";
import {
  months,
  currentMonth,
  currentYear,
  filterDataBySelectedYear,
  getWidgetData,
} from "src/utils/dashboardUtils";

function DashboardAdmin({ userId, userGroup, children }) {
  const [isWidgetDataLoading, setIsWidgetDataLoading] = useState(true);
  const [widgetsData, setWidgetsData] = useState({});
  const [state, setState] = useState({
    selectedYear: currentYear,
    selectedTab: currentMonth,
  });

  useEffect(() => {
    getWidgetData(
      setWidgetsData,
      setIsWidgetDataLoading,
      state.selectedYear,
      userId
    );
  }, [state.selectedYear]);

  const monthlyBookingsGraphData = {
    labels:
      currentYear === state.selectedYear
        ? months.slice(0, currentMonth + 1)
        : months,
    datasets: [
      {
        label: "Prenotazioni tour",
        backgroundColor: "#42A5F5",
        data: widgetsData?.tourAndVoucherData?.tourPerMonth,
      },
      {
        label: "Prenotazioni voucher",
        backgroundColor: "#9CCC65",
        data: widgetsData?.tourAndVoucherData?.voucherPerMonth,
      },
    ],
  };

  const widgetsDropdownData = [
    {
      color: "primary",
      total: utils.formatCurrency({
        number: widgetsData?.ordersData?.totalAmountOfYear,
      }),
      title: "Incassi",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.ordersData?.totalsPerMonth,
        state.selectedYear
      ),
    },
    {
      color: "info",
      total: widgetsData?.userData?.totalNewUserYear,
      title: "Nuovi clienti",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.userData?.monthlyNewUser,
        state.selectedYear
      ),
    },
    {
      color: "warning",
      total: widgetsData?.tourAndVoucherData?.totalBookingsAmount,
      title: "Prenotazioni",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.tourAndVoucherData?.tourPerMonth,
        state.selectedYear
      ),
    },
    {
      color: "danger",
      total: widgetsData?.wineryData?.totalNewUserYear,
      title: "Cantine",
      monthlyData: filterDataBySelectedYear(
        widgetsData?.wineryData?.monthlyNewUser,
        state.selectedYear
      ),
    },
  ];

  // The only difference between Admin Dashboard and Editor Dashboard is the abscence of the first widget ("Incassi") for the editor. Also "admin" can't see incassi, so we filter it out:
  const filteredWidgetsDropdownData = widgetsDropdownData.filter(
    (widget, index) => {
      // If userGroup is "editor" and it's the first widget (index 0), filter it out.
      if ((userGroup === "editor" || userGroup === "admin") && index === 0) {
        return false;
      }
      return true;
    }
  );

  const mostBookedToursData = widgetsData?.tourAndVoucherData?.topThreeTour;

  const changeSelectedTab = (tab) => {
    let selectedTab = null;
    const monthIndex = months.findIndex(
      (month) => month.toLowerCase() === tab.toLowerCase()
    );
    if (monthIndex !== -1) {
      selectedTab = monthIndex;
    }
    setState({ ...state, selectedTab });
  };

  const mapTopBookingsTableBody = (item, index) => {
    return (
      <CTableRow v-for="item in tableItems" key={index}>
        <CTableDataCell>{item.name || "-"}</CTableDataCell>
        <CTableDataCell>{item.cantina || "-"}</CTableDataCell>
        <CTableDataCell>
          <strong>{item.count || "-"}</strong>
        </CTableDataCell>
      </CTableRow>
    );
  };

  const changeSelectedYear = (e) => {
    setState({ ...state, selectedYear: Number(e.target.value) });
  };

  return (
    <>
      {/* Loading data */}
      {isWidgetDataLoading && (
        <div className="my-4">
          <AppLoadingSpinner />
        </div>
      )}

      {/* Data loaded */}
      {!isWidgetDataLoading && (
        <>
          {/* Selezione anno */}
          <CRow className="my-4">
            <CCol className="d-flex align-items-center gap-2">
              <YearSelector
                startingYear={2022}
                changeSelectedYear={changeSelectedYear}
              />
            </CCol>
          </CRow>

          {/* Widgets */}
          {filteredWidgetsDropdownData &&
            filteredWidgetsDropdownData.length > 0 && (
              <WidgetsDropdown
                selectedYear={state.selectedYear}
                widgetsData={filteredWidgetsDropdownData}
              />
            )}

          {/* Prenotazioni mensili */}
          {monthlyBookingsGraphData && (
            <MonthlyBarChart
              title="Prenotazioni mensili"
              selectedYear={state.selectedYear}
              data={monthlyBookingsGraphData}
              isLoading={isWidgetDataLoading}
            />
          )}

          {/* Ultime prenotazioni */}
          {children}

          {/* Tour più prenotati */}
          <CRow>
            <CCol>
              <TopMonthlyChart
                title="Tour più prenotati"
                selectedYear={state.selectedYear}
                selectedTab={state.selectedTab}
                changeSelectedTab={changeSelectedTab}
                tourData={mostBookedToursData}
                mapTableBody={mapTopBookingsTableBody}
                tableHeaderCells={["Tour", "Cantina", "Totale prenotazioni"]}
              />
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
}

export default DashboardAdmin;
