import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
};

export const productCategoriesSlice = createSlice({
  name: 'productCategories',
  initialState,
  reducers: {
    setProductCategories: (state, action) => {
      const data = action.payload;
      const parentData = {};
      if (Array.isArray(data) && data.length > 0) {
        // eslint-disable-next-line no-unused-vars
        const calcData = data.filter((item) => !item.parent_id).map((currentItem) => {
          parentData[currentItem._id] = currentItem.name;
          return false;
        });
      }
      let responseData = [];
      if (Array.isArray(data) && data.length > 0) {
        // eslint-disable-next-line no-unused-vars
        responseData = data
          .filter((item) => !!item.parent_id)
          .map((currentItem) => (
            { value: currentItem._id, label: `${parentData[currentItem.parent_id]} - ${currentItem.name}` }
          ))
          // eslint-disable-next-line arrow-body-style
          .sort((a, b) => {
            return (a.label > b.label) ? 1 : -1;
          });
      }
      state.data = responseData;
    },
    initProductCategories: (state) => {
      state.data = null;
    },
  },
});

export const {
  setProductCategories,
  initProductCategories,
} = productCategoriesSlice.actions;

export default productCategoriesSlice.reducer;
