/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  CCol, CFormInput, CFormSelect, CFormSwitch, CRow,
  CTooltip,
} from '@coreui/react';
import { useForm, Controller } from 'react-hook-form';

import PromoCodesService from 'src/services/api/PromoCodesService';
import AppList from 'src/components/ui/List/AppList';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import { format } from 'date-fns';

function PromoCodesList() {
  const {
    control, handleSubmit, reset, getValues, formState: { errors }, watch, resetField,
  } = useForm();

  const discountType = watch('discount_type');
  const validFromDate = watch('valid_from');

  const buildColumnsFn = () => [
    {
      key: 'code',
      label: 'Codice',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'value',
      label: 'Valore',
      sortable: false,
      _props: { scope: 'col' },
    },
    // {
    //   key: 'percentage',
    //   label: 'Percentuale',
    //   sortable: true,
    //   _props: { scope: 'col' },
    // },
    {
      key: 'min_spend',
      label: 'Min. di spesa',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'valid_from',
      label: 'Data inizio validità',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'valid_to',
      label: 'Data fine validità',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'n_of_uses',
      label: 'N. di utilizzi',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'max_of_uses',
      label: 'Max n. di utilizzi',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'max_of_uses_per_person',
      label: 'Max n. di utilizzi per persona',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'combinable',
      label: 'Combinabile',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'active',
      label: 'Attivo',
      sortable: false,
      _props: { scope: 'col' },
    },
    // {
    //   key: 'valid_for_tours',
    //   label: 'Valido per tour',
    //   sortable: false,
    //   _props: { scope: 'col' },
    // },
    // {
    //   key: 'valid_for_vouchers',
    //   label: 'Valido per voucher',
    //   sortable: false,
    //   _props: { scope: 'col' },
    // },
  ];

  const formatCreationData = (data) => {
    const newData = { ...data };
    newData.valid_from = newData.valid_from ? new Date(newData.valid_from).toISOString() : null;
    newData.valid_to = newData.valid_to ? new Date(newData.valid_to).toISOString() : null;
    newData.discount = {
      type: newData.discount_type,
      value: newData.value,
    };
    if (!newData.min_spend) delete newData.min_spend;
    delete newData.discount_type;
    delete newData.value;
    return newData;
  };

  const buildRowsFn = (item) => ({
    _id: item._id,
    code: item.code,
    value: item.value,
    min_spend: item.min_spend,
    valid_from: item.valid_from,
    valid_to: item.valid_to,
    n_of_uses: item.n_of_uses,
    max_of_uses: item.max_of_uses,
    max_of_uses_per_person: item.max_of_uses_per_person,
    combinable: item.combinable,
    active: item.active,
    valid_for_tours: item.valid_for_tours,
    valid_for_vouchers: item.valid_for_vouchers,
  });

  const mapListFn = (item) => ({
    _id: item._id,
    code: item.code,
    value: item.discount?.type === 'fixed' ? `${item.discount?.value}€` : `${item.discount?.value}%`,
    min_spend: item.min_spend || '-',
    valid_from: item.valid_from ? format(new Date(item.valid_from), 'dd/MM/yyyy') : '-',
    valid_to: item.valid_to ? format(new Date(item.valid_to), 'dd/MM/yyyy') : '-',
    n_of_uses: item.n_of_uses || '-',
    max_of_uses: item.max_of_uses || '-',
    max_of_uses_per_person: item.max_of_uses_per_person || '-',
    combinable: item.combinable ? 'Si' : 'No',
    active: item.active ? 'Si' : 'No',
    valid_for_tours: item.valid_for_tours ? 'Si' : 'No',
    valid_for_vouchers: item.valid_for_vouchers ? 'Si' : 'No',
  });

  const onFocusDiscountType = () => {
    resetField('value');
  };

  const creationBodyFn = () => (
    <CRow md={{ cols: 2, gutter: 2 }}>
      {/* Codice */}
      <CCol md={6}>
        <Controller
          name="code"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.code}
              feedback={errors?.code ? composeErrorFormType(errors.code) : null}
              type="text"
              id="promo-code-code"
              label="Codice"
              placeholder="Inserisci codice sconto"
              {... field}
            />
          )}
        />
      </CCol>
      {/* Descrizione */}
      <CCol md={6}>
        <Controller
          name="description"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.description}
              feedback={errors?.description ? composeErrorFormType(errors.description) : null}
              type="text"
              id="promo-code-description"
              label="Descrizione"
              placeholder="Inserisci descrizione del codice sconto"
              {... field}
            />
          )}
        />
      </CCol>
      {/* Data inizio validità codice sconto */}
      <CCol md={6}>
        <Controller
          name="valid_from"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.valid_from}
              feedback={errors?.valid_from ? composeErrorFormType(errors.valid_from) : null}
              type="date"
              id="promo-code-valid_from"
              label="Data inizio validità"
              placeholder="Inserisci data inizio validità del codice sconto"
              {... field}
            />
          )}
        />
      </CCol>
      {/* Data fine validità codice sconto */}
      <CCol md={6}>
        <Controller
          name="valid_to"
          defaultValue=""
          control={control}
          rules={{
            required: false,
          }}
          render={({ field }) => (
            <CFormInput
              disabled={!validFromDate}
              invalid={!!errors.valid_to ||
                (validFromDate && new Date(field.value) < new Date(validFromDate))}
              feedback={errors?.valid_to ? composeErrorFormType(errors.valid_to) : null}
              type="date"
              id="promo-code-valid_to"
              label="Data fine validità"
              min={validFromDate}
              placeholder="Inserisci data fine validità del codice sconto"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        {/* Tipologia di sconto (percentuale o importo fisso) */}
        <Controller
          name="discount_type"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormSelect
              invalid={!!errors.discount_type}
              feedback={errors?.discount_type ? composeErrorFormType(errors.discount_type) : null}
              id="promo-code-discount_type"
              label="Tipologia di sconto"
              onFocus={onFocusDiscountType}
              {...field}
            >
              <option value="">Seleziona tipologia di sconto</option>
              <option value="percentage">Percentuale</option>
              <option value="fixed">Importo fisso</option>
            </CFormSelect>
          )}
        />
      </CCol>
      {/* Valore dello sconto */}
      <CCol md={6}>
        <Controller
          disabled={!discountType}
          name="value"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.value}
              feedback={errors?.value ? composeErrorFormType(errors.value) : null}
              type="number"
              id="promo-code-value"
              label="Valore sconto"
              placeholder="Inserisci valore sconto"
              min={1}
              max={discountType === 'percentage' ? 100 : null}
              {... field}
            />
          )}
        />
      </CCol>
      {/* Minimo di spesa */}
      <CCol md={6}>
        <Controller
          name="min_spend"
          defaultValue=""
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.min_spend}
              feedback={errors?.min_spend ? composeErrorFormType(errors.min_spend) : null}
              type="number"
              id="promo-code-min_spend"
              label="Minimo di spesa"
              placeholder="Inserisci minimo di spesa"
              min={0}
              {... field}
            />
          )}
        />
      </CCol>
      {/* Numero massimo di utilizzi */}
      <CCol md={6}>
        <Controller
          name="max_of_uses"
          defaultValue=""
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.max_of_uses}
              feedback={errors?.max_of_uses ? composeErrorFormType(errors.max_of_uses) : null}
              type="number"
              id="promo-code-max_of_uses"
              label="Numero massimo di utilizzi"
              placeholder="Inserisci numero massimo di utilizzi"
              min={1}
              {... field}
            />
          )}
        />
      </CCol>
      {/* Numero di utilizzi massimo per persona */}
      <CCol md={6}>
        <Controller
          name="max_of_uses_per_person"
          defaultValue=""
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.max_of_uses_per_person}
              feedback={errors?.max_of_uses_per_person ?
                composeErrorFormType(errors.max_of_uses_per_person) : null}
              type="number"
              id="promo-code-max_of_uses_per_person"
              label="Numero di utilizzi per persona"
              placeholder="Inserisci numero massimo di utilizzi per persona"
              min={1}
              {... field}
            />
          )}
        />
      </CCol>
      {/* Valido per tour specifici */}
      <CCol md={6}>
        {/* <Controller
          name="allowed_tour_categories"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            // <AsyncSelect
            //   {...field}
            //   isMulti
            //   loadOptions={(inputValue) => loadCities(inputValue)}
            //   placeholder="Seleziona tour specifici"
            // />
            <CFormCheck
              inline
              id="promo-code-allowed_tour_categories"
              label="Valido per tour specifici"
              // {...field}
            />
          )}
        /> */}
      </CCol>
      {/* Combinabile */}
      <CCol md={4}>
        <Controller
          name="combinable"
          defaultValue={false}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormSwitch
              id="promo-code-combinable"
              label="Combinabile con altri codici"
              checked={field.value}
              onClick={(e) => field.onChange(e.target.checked)}
              {...field}
            />
          )}
        />
      </CCol>
      {/* Codice promo attivo */}
      <CCol md={4}>
        <Controller
          name="active"
          defaultValue={false}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormSwitch
              id="promo-code-active"
              label="Codice promo attivo"
              checked={field.value}
              onClick={(e) => field.onChange(e.target.checked)}
              {...field}
            />
          )}
        />
      </CCol>
      {/* Codice sconto copre fee cantina */}
      <CCol md={4}>
        <Controller
          name="winery_discount"
          defaultValue={false}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormSwitch
              id="promo-code-winery_discount"
              label="Copre fee cantina"
              checked={field.value}
              onClick={(e) => field.onChange(e.target.checked)}
              {...field}
            />
          )}
        />
      </CCol>
      {/* Codice promo valido per acquisto tour/voucher/entrambi? */}
      <CCol md={4}>
        <Controller
          name="valid_for_tours"
          defaultValue={false}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormSwitch
              id="promo-code-valid_for_tours"
              label="Valido per acquisto tour"
              checked={field.value}
              onClick={(e) => field.onChange(e.target.checked)}
              {...field}
            />
          )}
        />
      </CCol>
      <CCol md={4}>
        <Controller
          name="valid_for_vouchers"
          defaultValue={false}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <CFormSwitch
              id="promo-code-valid_for_vouchers"
              label="Valido per acquisto voucher"
              checked={field.value}
              onClick={(e) => field.onChange(e.target.checked)}
              {...field}
            />
          )}
        />
      </CCol>
      {/* Codice Welcome */}
      <CCol md={4}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Controller
            name="is_welcome_code"
            defaultValue={false}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <CFormSwitch
                id="promo-code-is_welcome_code"
                label="Codice Welcome"
                checked={field.value}
                onClick={(e) => field.onChange(e.target.checked)}
                {...field}
              />
            )}
          />
          <CTooltip
            content="Attenzione, selezionando questa opzione questo codice sconto andrà a sostituire quello di welcome precedentemente creato, a partire dalla data di inizio validità."
          >
            <span className="badge bg-warning ms-2">?</span>
          </CTooltip>
        </div>
      </CCol>
    </CRow>
  );

  return (
    <section id="promo_codes">
      <AppList
        sectionId="promo_codes"
        sectionTitle="Lista Codici Sconto"
        SectionServiceClass={PromoCodesService}
        sectionPath="/promo-codes"
        mapListFn={mapListFn}
        buildColumnsFn={buildColumnsFn}
        buildRowsFn={buildRowsFn}
        creationTitle="Nuovo Codice Sconto"
        creationBodyFn={() => creationBodyFn()}
        evalCreation={handleSubmit}
        clearCreationModel={() => reset({})}
        formatCreationData={formatCreationData}
        initialSortField="code"
        // isExportable
      />
    </section>
  );
}

export default PromoCodesList;
