/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  CRow,
  CCol,
  CFormCheck,
} from '@coreui/react';

import TimesCheckbox from '../TimesCheckbox/TimesCheckbox';

// const TOUR_BOOKING_OPTIONS = [
//   '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00',
//   '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00',
// ];

const TOUR_BOOKING_OPTIONS = [
  '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
  '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30',
  '21:00', '21:30', '22:00'];

function TimeTable(props) {
  const {
    data, onChange, max, description,
  } = props;
  const [timeTable, setTimeTable] = useState({
    monday: {
      closed: false,
      timetable: [],
    },
    tuesday: {
      closed: false,
      timetable: [],
    },
    wednesday: {
      closed: false,
      timetable: [],
    },
    thursday: {
      closed: false,
      timetable: [],
    },
    friday: {
      closed: false,
      timetable: [],
    },
    saturday: {
      closed: false,
      timetable: [],
    },
    sunday: {
      closed: false,
      timetable: [],
    },
  });

  useEffect(() => {
    const newTimeTable = { ...timeTable, ...data };
    setTimeTable(newTimeTable);
  }, []);

  const toggleDayAvailability = (dayName) => {
    const newTimeTable = { ...timeTable };
    newTimeTable[dayName].closed = !newTimeTable[dayName].closed;
    setTimeTable(newTimeTable);
    onChange(newTimeTable);
  };

  const handleChangeTimetable = (params) => {
    const {
      day, value,
    } = params;
    const newTimeTable = { ...timeTable };
    newTimeTable[day].timetable = value;
    setTimeTable(newTimeTable);
    onChange(newTimeTable);
  };

  const formatDescription = (dayName, gender) => {
    switch (gender) {
      case 'm':
        return description.replace('{day}', `il ${dayName}`);
      case 'f':
      default:
        return description.replace('{day}', `la ${dayName}`);
    }
  };

  return (
    <>
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>LUNEDÌ</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="mondayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.monday.closed}
                onChange={() => toggleDayAvailability('monday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.monday.closed === true}
            title="Orario"
            description={formatDescription('lunedì', 'm')}
            data={timeTable.monday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'monday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>MARTEDÌ</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="tuesdayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.tuesday.closed}
                onChange={() => toggleDayAvailability('tuesday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.tuesday.closed === true}
            title="Orario"
            description={formatDescription('martedì', 'm')}
            data={timeTable.tuesday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'tuesday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>MERCOLEDÌ</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="wednesdayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.wednesday.closed}
                onChange={() => toggleDayAvailability('wednesday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.wednesday.closed === true}
            title="Orario"
            description={formatDescription('mercoledì', 'm')}
            data={timeTable.wednesday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'wednesday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>GIOVEDÌ</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="thursdayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.thursday.closed}
                onChange={() => toggleDayAvailability('thursday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.thursday.closed === true}
            title="Orario"
            description={formatDescription('giovedì', 'm')}
            data={timeTable.thursday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'thursday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>VENERDÌ</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="fridayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.friday.closed}
                onChange={() => toggleDayAvailability('friday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.friday.closed === true}
            title="Orario"
            description={formatDescription('venerdì', 'm')}
            data={timeTable.friday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'friday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>SABATO</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="saturdayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.saturday.closed}
                onChange={() => toggleDayAvailability('saturday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.saturday.closed === true}
            title="Orario"
            description={formatDescription('sabato', 'm')}
            data={timeTable.saturday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'saturday', value })}
          />
        </CCol>
      </CRow>
      <hr />
      <CRow className="pb-4">
        <CCol md={4}>
          <CRow>
            <CCol md={6}>
              <h5>DOMENICA</h5>
            </CCol>
            <CCol md={6}>
              <CFormCheck
                inline
                id="sundayAvailableCheckbox"
                label="Chiuso"
                checked={timeTable.sunday.closed}
                onChange={() => toggleDayAvailability('sunday')}
              />
            </CCol>
          </CRow>
          <hr />
        </CCol>
        <CCol className="border-left-md" md={8}>
          <TimesCheckbox
            dividers
            maxSelection={max}
            disabled={timeTable.sunday.closed === true}
            title="Orario"
            description={formatDescription('domenica', 'f')}
            data={timeTable.sunday.timetable}
            options={TOUR_BOOKING_OPTIONS}
            onChange={(value) => handleChangeTimetable({ day: 'sunday', value })}
          />
        </CCol>
      </CRow>
    </>
  );
}

TimeTable.propTypes = {
  data: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  description: PropTypes.string,
};

TimeTable.defaultProps = {
  data: {},
  max: 0,
  description: 'Select times',
};

export default TimeTable;
