/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CNav,
  CNavItem,
  CNavLink,
  CTable,
  CTableBody,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
} from "@coreui/react";

// Utils
import { currentYear, currentMonth } from "src/utils/dashboardUtils";

function TopMonthlyChart({
  selectedYear,
  months,
  selectedTab,
  changeSelectedTab,
  tourData,
  mapTableBody,
  tableHeaderCells,
  title,
}) {
  const mapTableHead = (tableCell, index) => (
    <CTableHeaderCell key={index} style={{width: "200px"}}>{tableCell}</CTableHeaderCell>
  );

  return (
    <CCard className="mb-4">
      <CCardHeader>
        <h4 className="card-title mb-2">
          {title} {selectedYear}
        </h4>
        <CNav variant="tabs" className="card-header-tabs " role="tablist">
          {months.map((month, index) => (
            <CNavItem key={month}>
              <CNavLink
                data-tab={month}
                active={selectedTab === index}
                onClick={() => changeSelectedTab(month)}
                className="cursor-pointer"
                style={{ textTransform: "capitalize" }}
                disabled={
                  currentYear === Number(selectedYear)
                    ? currentMonth < index
                    : false
                }
              >
                {month}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
      </CCardHeader>
      <CCardBody className="">
        <CTable align="middle" hover responsive>
          <CTableHead color="light" className="fw-bolder">
            <CTableRow>{tableHeaderCells.map(mapTableHead)}</CTableRow>
          </CTableHead>
          <CTableBody>
            {tourData[selectedTab] && tourData[selectedTab].length > 0 ? (
              tourData[selectedTab].map(mapTableBody)
            ) : (
              <CTableRow>
                <CTableDataCell colSpan={3}>
                  Nessun risultato per questo mese.
                </CTableDataCell>
              </CTableRow>
            )}
          </CTableBody>
        </CTable>
      </CCardBody>
    </CCard>
  );
}

const tourDataItemShape = PropTypes.shape({
  name: PropTypes.string,
  cantina: PropTypes.string,
  count: PropTypes.number,
});

TopMonthlyChart.propTypes = {
  selectedYear: PropTypes.number,
  months: PropTypes.arrayOf(PropTypes.string),
  selectedTab: PropTypes.number,
  changeSelectedTab: PropTypes.func,
  tourData: PropTypes.arrayOf(PropTypes.arrayOf(tourDataItemShape)),
  mapTableBody: PropTypes.func,
  tableHeaderCells: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

TopMonthlyChart.defaultProps = {
  selectedYear: currentYear,
  months: [
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ],
  selectedTab: currentMonth,
  changeSelectedTab: () => {},
  tourData: [],
  mapTableBody: () => {},
};

export default TopMonthlyChart;
