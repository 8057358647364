import ApiProxyService from './apiProxyService';

export default class ProductCategoriesService extends ApiProxyService {
  getList({
    paginate,
    page,
    order = 'name',
    sort = 'ASC',
    filters,
    okCallback,
    koCallback,
  }) {
    let queryParams = {
      order_by: order, sort_by: sort,
    };
    if (paginate) {
      queryParams.paginate = paginate;
    }
    if (page) {
      queryParams.page = page;
    }
    let searchFilters = {};
    if (filters) {
      searchFilters = { ...filters };
      if (filters.search && filters.search.length > 0) {
        searchFilters['??_^name'] = filters.search;
        delete searchFilters.search;
      }
      queryParams = {
        ...queryParams, ...searchFilters,
      };
    }
    const path = '/product_categories';
    super.getList(path, queryParams, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `/product_categories/${itemId}`;
    super.getItem(path, okCallback, koCallback);
  }
}
