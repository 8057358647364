import ApiProxyService from './apiProxyService';
import utils from './utils/utils';

export default class VouchersService extends ApiProxyService {
  BASE_PATH = '/vouchers';

  getList({
    paginate,
    page,
    order = 'desc',
    sort = 'code',
    searchFor = null,
    filters,
    okCallback,
    koCallback,
  }) {
    // Define searchBy mapping
    const searchByMap = {
      name: 'order-user.name',
      code: 'code',
      sender_email: 'order-user.email',
      recipient_email: 'variants-email',
      order_number: 'order-order_number',
    };
    // Initialize queryParams with only defined values
    const queryParams = {
      paginate,
      page,
      order_by: order,
      sort_by: sort,
      lookup: '[order_id]',
    };
    if (filters) {
      const { search, customFilter, ...otherFilters } = filters;
      // Handle search
      if (search && search.length > 0 && searchByMap[searchFor]) {
        queryParams[`??_^${searchByMap[searchFor]}`] = search;
      }
      // Handle custom filter
      if (customFilter && customFilter.key && customFilter.value !== undefined) {
        queryParams[customFilter.key] = customFilter.value;
      }
      // Add other filters
      if (otherFilters && typeof otherFilters === 'object') {
        Object.entries(otherFilters).forEach(([key, value]) => {
          if (value !== undefined) {
            queryParams[key] = value;
          }
        });
      }
    }

    const path = `${this.BASE_PATH}`;
    super.getList(path, queryParams, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}`;
    const pathWithQueryParams = utils.buildPathWithQueryParams(path, { lookup: '[order_id]' });
    super.getItem(pathWithQueryParams, okCallback, koCallback);
  }

  addItem(body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}`;
    const parsedBody = { ...body };
    parsedBody.initial_value = body.variants.price;
    super.addItem(path, parsedBody, okCallback, koCallback);
  }

  updateItem(id, body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}`;
    super.updateItem(path, body, okCallback, koCallback);
  }

  deleteItem(deleteInfo, okCallback, koCallback) {
    const deletePromisesArray = [];
    if (typeof deleteInfo === 'string' || typeof deleteInfo === 'number') {
      deletePromisesArray.push(
        new Promise((resolve, reject) => {
          super.deleteItem(`${this.BASE_PATH}/${deleteInfo}`, (res) => resolve(res), (err) => reject(err));
        }),
      );
    } else if (Array.isArray(deleteInfo)) {
      deleteInfo.forEach((currentItem) => {
        deletePromisesArray.push(
          new Promise((resolve, reject) => {
            super.deleteItem(`${this.BASE_PATH}/${currentItem}`, (res) => resolve(res), (err) => reject(err));
          }),
        );
      });
    }
    Promise.allSettled(deletePromisesArray)
      .then((results) => {
        const rejectedPromises = results.filter((currentResult) => currentResult.status === 'rejected');
        if (rejectedPromises.length <= 0) okCallback();
        else koCallback([...rejectedPromises]);
      });
  }

  downloadVoucher(id, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}/download`;
    const config = {
      responseType: 'blob',
    };

    super.getItem(path, okCallback, koCallback, config);
  }
}
