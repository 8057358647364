import UsersService from 'src/services/api/UsersService';

const usersService = new UsersService();

export const months = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

export const currentMonth = new Date().getMonth();

export const currentYear = new Date().getFullYear();

export const filterDataBySelectedYear = (data, selectedYear) => {
  if (!data) return null;
  // If current year is selected, return data until current month
  if (currentYear === selectedYear) {
    return data.slice(0, currentMonth + 1);
  }
  return data;
};

export const orderStatusLegend = [
  {
    label: 'Cancellato',
    color: 'primary',
    percentage: 100,
  },
  {
    label: 'Rifiutato',
    color: 'danger',
    percentage: 100,
  },
  {
    label: 'In lavorazione',
    color: 'warning',
    percentage: 35,
  },
  {
    label: 'Confermato',
    color: 'success',
    percentage: 70,
  },
  {
    label: 'Completato',
    color: 'success',
    percentage: 100,
  },
];

export function getOrderStatus(item) {
  const orderStatusConditions = [
    {
      condition: item.products.every((product) => product.deleted_at),
      label: 'Cancellato',
    },
    {
      condition: item.products.every(
        (product) => product.contact_rejected_at || product.deleted_at,
      ),
      label: 'Rifiutato',
    },
    {
      condition: item.products.every(
        (product) => product.contact_confirmed_at || product.deleted_at,
      ),
      label: 'Confermato',
    },
    {
      condition: item.completed_at,
      label: 'Completato',
    },
  ];

  let orderStatus = orderStatusConditions.find((condition) => condition.condition);

  if (!orderStatus) {
    orderStatus = {
      label: 'In lavorazione',
    };
  }

  const progressStatus = orderStatusLegend.find(
    (status) => status.label === orderStatus.label,
  );

  const progressColor = progressStatus ? progressStatus.color : 'primary';
  const progressValue = progressStatus ? progressStatus.percentage : 0;

  return {
    orderStatus,
    progressStatus,
    progressColor,
    progressValue,
  };
}

export function getWidgetData(
  setWidgetsData,
  setIsWidgetDataLoading,
  selectedYear,
  userId,
) {
  const okgetWidgetData = (response) => {
    const { data } = response;
    // console.log('response getWidgetData', data);
    setWidgetsData(data);
    setIsWidgetDataLoading(false);
  };

  const kogetWidgetData = (error) => {
    throw new Error(error);
  };
  usersService.getWidgetsData(
    userId,
    selectedYear,
    okgetWidgetData,
    kogetWidgetData,
  );
}
