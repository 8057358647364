import React, { Suspense, useEffect, useState } from 'react';
import {
  BrowserRouter, Route, Routes, Navigate,
} from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { clearUser, setUser } from 'src/redux/slices/user.slice';
import { setProductCategories } from 'src/redux/slices/productCategories.slice';
import UserService from './services/api/UserService';
import ProductCategoriesService from './services/api/ProductCategoriesService';
import './scss/style.scss';
import AppLoadingSpinner from './components/ui/AppLoadingSpinner';

// Containers
import DefaultLayout from './layout/DefaultLayout';

// Pages
import Login from './views/pages/login/Login';
import Register from './views/pages/register/Register';
import ForgotPassword from './views/pages/forgot-password/ForgotPassword';
import ResetPassword from './views/pages/reset-password/ResetPassword';

function App() {
  const [appInit, setAppInit] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const productCategories = useSelector((state) => state.productCategories);
  const userService = new UserService();
  const productCategoriesService = new ProductCategoriesService();

  const redirectToLogin = () => {
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/forgot-password' &&
      window.location.pathname !== '/reset-password'
    ) {
      const loginRedirect = `?loginRedirect=${encodeURIComponent(
        window.location.pathname,
      )}`;
      const queryString =
        window.location.search !== ''
          ? encodeURIComponent(`${window.location.search}`)
          : '';
      window.location.href = `/login${loginRedirect}${queryString}`;
    }
  };

  const okProductCategories = (response) => {
    dispatch(setProductCategories(response.data));
  };

  const okGetUser = (responseUserMe) => {
    dispatch(setUser(responseUserMe.data));
    setIsUser(true);
    productCategoriesService.getList({ okCallback: okProductCategories });
  };

  const koGetUser = () => {
    dispatch(clearUser());
    window.localStorage.removeItem('auth');
    redirectToLogin();
    setAppInit(true);
  };

  useEffect(() => {
    if (window.localStorage.getItem('auth')) {
      userService.getMe(okGetUser, koGetUser);
    } else {
      redirectToLogin();
    }
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/forgot-password' ||
      window.location.pathname === '/reset-password'
    ) setAppInit(true);
  }, []);

  useEffect(() => {
    if (
      (productCategories?.data && user?.value) ||
      window.location.pathname === '/forgot-password' ||
      window.location.pathname === '/reset-password'
    ) {
      setAppInit(true);
    }
  }, [user, productCategories]);

  const loading = (
    <AppLoadingSpinner />
  );

  if (appInit === false) {
    return loading;
  }
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={isUser === false ? <Login setIsUser={setIsUser} /> : <Navigate to="/dashboard" />} />
          <Route exact path="/register" name="Register Page" element={isUser === false ? <Register /> : <Navigate to="/dashboard" />} />
          <Route exact path="/forgot-password" name="Forgot Password Page" element={<ForgotPassword />} />
          <Route exact path="/reset-password" name="Reset Password Page" element={<ResetPassword />} />
          <Route path="*" name="Home" element={isUser === true ? <DefaultLayout /> : <Navigate to="/login" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
