/* eslint-disable */
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CFormLabel,
  CButton,
  CBadge,
  CCard,
  CCardHeader,
  CCardBody,
  CFormTextarea,
  CTable,
  CTableHead,
  CTableBody,
  CTooltip,
} from "@coreui/react";

// ICONS
import CIcon from "@coreui/icons-react";
import { cilFile, cilInfo } from "@coreui/icons";

// SERVICES
import SpecialVouchersService from "src/services/api/SpecialVouchersService";
import composeErrorFormType from "src/utils/composeErrorFormType";

// COMPONENTS
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";
import utils from "src/services/api/utils/utils";
import AppDetail from "src/components/ui/Detail/AppDetail";

const SpecialVouchersDetail = () => {
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm();
  const [state, setState] = useState({
    loading: true,
    model: null,
    processingBooking: false,
    newCodesQuantity: "",
  });

  const specialVouchersService = new SpecialVouchersService();

  const formatModel = (response) => {
    const newModel = { ...response.data };
    setValue("voucher_name", newModel.name || "");
    setValue(
      "voucher_value",
      utils.formatCurrency({ number: newModel.value }) || ""
    );
    setValue("voucher_quantity", newModel.quantity || "");
    setValue("voucher_description", newModel.description || "");
    setValue(
      "expiration_date",
      newModel.expiring_at
        ? format(new Date(newModel.expiring_at.substring(0, 10)), "yyyy-MM-dd") // substring to avoid a bug that add 1 day if the hour is very close to midnight
        : "-"
    );
    setValue("voucher_codes", newModel.vouchers || "");
    return newModel;
  };

  const onSubmit = (data) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      const body = {};
      body.description = data.voucher_description;
      body.name = data.voucher_name;
      body.expiring_at = new Date(data.expiration_date).toISOString();

      specialVouchersService.updateItem(
        state.model["_id"],
        body,
        okEditCallback,
        koEditCallback
      );
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, salvando le modifiche...",
        success: "Dato modificato con successo!",
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const handleCodeQuantityChange = (event) => {
    let newCodesQuantity = event.target.value;
    if (event.target.value < 0) {
      newCodesQuantity = "";
      return;
    }
    setState({ ...state, newCodesQuantity: newCodesQuantity });
  };

  const onAddNewCodes = () => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        reset({ new_codes_quantity: "" });
        setState({
          ...state,
          loading: false,
          model: formatModel(response),
          newCodesQuantity: "",
        });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      specialVouchersService.addNewCodes(
        state.model["_id"],
        { quantity: state.newCodesQuantity },
        okEditCallback,
        koEditCallback
      );
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, salvando le modifiche...",
        success: "Codici aggiunti con successo!",
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const handleCsvExport = () => {
    const savePromise = new Promise((resolve, reject) => {
      const okGetCsv = (response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `specialVoucher${state?.model?.name}.csv`;
        link.click();
        link.remove();
        // setState({ ...state, loading: false, model: formatModel(response) });
        resolve();
      };

      const koGetCsv = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      specialVouchersService.getCsv(state?.model["_id"], okGetCsv, koGetCsv);
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, eseguendo l'operazione...",
        success: "Dettagli voucher esportati con successo!",
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const mapAssociatedTours = (tour, index) => (
    <li key={index}>
      {tour.name} - {tour.package_name}
    </li>
  );

  const getCodeStatus = (code) => {
    const codeStatus = {};
    if (code.is_used) {
      codeStatus.label = "Usato";
      codeStatus.color = "success";
    } else if (code.is_pending) {
      codeStatus.label = "In lavorazione";
      codeStatus.color = "warning";
    } else {
      codeStatus.label = "Non usato";
      codeStatus.color = "danger";
    }

    return (
      <CBadge color={codeStatus.color} style={{ width: "90px" }}>
        {codeStatus.label}
      </CBadge>
    );
  };

  useEffect(() => {
    if (id !== null && typeof id !== "undefined") {
      const okGetCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || "Nessun errore";
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      specialVouchersService.getItem(id, okGetCallback, koGetCallback);
    }
  }, [id]);

  const handleReset = () => {
    const resetModel = {
      voucher_code: state.model.code || "",
      recipient_name: state.model.variants.addresse || "",
      recipient_email: state.model.variants.email || "",
      voucher_price: utils.formatCurrency({
        number: state.model.variants.price,
      }),
      voucher_balance: utils.formatCurrency({
        number: state.model.remaining_amount,
      }),
      recipient_message: state.model.variants.message || "",
      expiration_date: format(new Date(state.model.expiring_at), "dd/MM/yyyy"),
      send_date: format(new Date(state.model.variants.send_dt), "dd/MM/yyyy"),
    };

    reset({ ...resetModel });
  };

  if (state?.loading === true) return <AppLoadingSpinner />;

  return (
    <AppDetail
      actions={false}
      saveActions={false} // Per ora FALSE perchè non prevediamo la modifica dei voucher speciali
      name="Dettaglio voucher speciale"
      detail={`Voucher speciale per ${state.model?.name}`}
      sectionId="special_vouchers"
      type="special voucher"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
      tabsHeaders={[
        {
          index: "special-voucher-tab",
          label: "DATI VOUCHER",
        },
        {
          index: "new-codes-tab",
          label: "AGGIUNTA NUOVI CODICI",
        },
        {
          index: "associated-codes-tab",
          label: "CODICI ASSOCIATI AL VOUCHER",
        },
      ]}
      tabsContents={[
        {
          index: "special-voucher-tab",
          content: (
            <CForm className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <CCol md={12} sm={12}>
                <CCard>
                  <CCardHeader>Dettagli del voucher</CCardHeader>
                  <CCardBody>
                    <CRow className="mb-3">
                      <CCol md={12} sm={12}>
                        <Controller
                          name="voucher_name"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <CFormInput
                              disabled
                              invalid={!!errors.voucher_name}
                              feedback={
                                errors?.voucher_name
                                  ? composeErrorFormType(errors.voucher_name)
                                  : null
                              }
                              type="text"
                              id="voucher_name"
                              label="Nome società destinataria"
                              placeholder="Nome società destinataria"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={12} sm={12}>
                        <Controller
                          name="voucher_value"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <CFormInput
                              disabled
                              invalid={!!errors.voucher_value}
                              feedback={
                                errors?.voucher_value
                                  ? composeErrorFormType(errors.voucher_value)
                                  : null
                              }
                              type="text"
                              id="voucher_value"
                              label="Importo"
                              placeholder="Importo voucher"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md={12} sm={12}>
                        <Controller
                          name="voucher_quantity"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <CFormInput
                              invalid={!!errors.voucher_quantity}
                              disabled
                              feedback={
                                errors?.voucher_quantity
                                  ? composeErrorFormType(
                                      errors.voucher_quantity
                                    )
                                  : null
                              }
                              type="number"
                              min={1}
                              id="voucher_quantity"
                              label="Quantità"
                              placeholder="Quantità voucher"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol md={12} sm={12}>
                        <Controller
                          name="voucher_description"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <CFormTextarea
                              invalid={!!errors.voucher_description}
                              feedback={
                                errors?.voucher_description
                                  ? composeErrorFormType(
                                      errors.voucher_description
                                    )
                                  : null
                              }
                              type="text"
                              id="voucher_description"
                              label="Descrizione"
                              placeholder="Descrizione del voucher"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol md={6} sm={6}>
                        <Controller
                          name="expiration_date"
                          control={control}
                          rules={{ required: true }}
                          defaultValue=""
                          render={({ field }) => (
                            <CFormInput
                              invalid={!!errors.expiration_date}
                              feedback={
                                errors?.expiration_date
                                  ? composeErrorFormType(errors.expiration_date)
                                  : null
                              }
                              type="date"
                              min={new Date().toISOString().split("T")[0]}
                              id="expiration_date"
                              label="Data di scadenza"
                              placeholder="Data di scadenza del voucher"
                              {...field}
                            />
                          )}
                        />
                      </CCol>
                    </CRow>
                    <CRow className="mb-3">
                      <CCol md={12} sm={12}>
                        <CFormLabel>Tour associati</CFormLabel>
                        <CCard
                          className="pt-2 overflow-auto"
                          style={{ maxHeight: "200px" }}
                        >
                          <ul>{state?.model?.tours.map(mapAssociatedTours)}</ul>
                        </CCard>
                      </CCol>
                    </CRow>
                    <CButton
                      className="mt-3"
                      color="primary"
                      type="submit"
                      disabled={!isDirty}
                    >
                      Salva modifiche
                    </CButton>
                  </CCardBody>
                </CCard>
              </CCol>
            </CForm>
          ),
        },
        {
          index: "new-codes-tab",
          content: (
            <CRow>
              <CCol md={12} sm={12}>
                <CCard>
                  <CCardHeader>Aggiungi nuovi codici</CCardHeader>
                  <CCardBody>
                    <CCol md={12} sm={12}>
                      <CFormInput
                        invalid={!!errors.new_codes_quantity}
                        onChange={handleCodeQuantityChange}
                        value={state.newCodesQuantity}
                        feedback={
                          errors?.new_new_codes_quantity
                            ? composeErrorFormType(errors.new_codes_quantity)
                            : null
                        }
                        type="number"
                        min={1}
                        id="new_codes_quantity"
                        label="Inserisci la quantità di nuovi codici da generare"
                        placeholder="Quantità nuovi codici"
                      />
                      <CButton
                        className="mt-3"
                        color="primary"
                        onClick={onAddNewCodes}
                        disabled={
                          state.newCodesQuantity <= 0 ||
                          state.newCodesQuantity === ""
                        }
                      >
                        Aggiungi codici
                      </CButton>
                    </CCol>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          ),
        },
        {
          index: "associated-codes-tab",
          content: (
            <>
              <CRow className="mb-4">
                <CCol
                  md={12}
                  sm={12}
                  className="d-flex flex-column justify-content-center"
                >
                  <CFormLabel>
                    Esporta i dati del voucher ed i suoi codici in formato CSV
                  </CFormLabel>
                  <CButton
                    style={{ width: "150px" }}
                    color="primary"
                    disabled={false}
                    onClick={handleCsvExport}
                  >
                    <CIcon icon={cilFile} className="icon-button" />
                    Esporta
                  </CButton>
                </CCol>
              </CRow>
              <CRow>
                <CCard style={{ height: "450px" }}>
                  <CTable striped responsive>
                    <CTableHead
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                      }}
                    >
                      <tr>
                        <th>Codice</th>
                        <th>
                          Stato&nbsp;&nbsp;
                          <CTooltip
                            content={`Lo stato "In lavorazione" indica che l'utente ha usato il codice ed è in attesa di conferma dalla cantina`}
                          >
                            <CIcon
                              icon={cilInfo}
                              className="icon-button cursor-pointer"
                            />
                          </CTooltip>
                        </th>
                      </tr>
                    </CTableHead>
                    <CTableBody>
                      {state.model?.vouchers?.length > 0 &&
                        state.model.vouchers?.map((code, index) => (
                          <tr key={index}>
                            <td>{code.code}</td>
                            <td>{getCodeStatus(code)}</td>
                          </tr>
                        ))}
                    </CTableBody>
                  </CTable>
                </CCard>
              </CRow>
            </>
          ),
        },
      ]}
    />
  );
};

export default SpecialVouchersDetail;
