/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CBadge,
  CCol,
  CFormInput,
  CRow,
  CForm,
  CButton,
  CInputGroup,
  CFormLabel,
  CFormSelect,
} from '@coreui/react';

import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';

import OrdersService from 'src/services/api/OrdersService';
import AppList from 'src/components/ui/List/AppList';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import utils from 'src/services/api/utils/utils';

const filterOptions = [
  {
    label: 'Seleziona filtro',
    value: '',
  },
  {
    label: 'Nome ordinante',
    value: 'name',
  },
  {
    label: 'Email ordinante',
    value: 'email',
  },
  {
    label: 'Numero ordine',
    value: 'order_number',
  },
  {
    label: 'Nome cantina',
    value: 'winery_name',
  },
];

function OrdersList() {
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();

  const [statusFilter, setStatusFilter] = useState('');
  const userGroup = useSelector((state) => state.user.value.user_group);
  const buildColumnsFn = () => [
    {
      key: 'order_number',
      label: 'Numero ordine',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'created_at',
      label: 'Data ordine',
      sortable: false,
      _props: { scope: 'col' },
    },
    {
      key: 'user_name',
      label: 'Nome e Cognome',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'tour',
      label: 'Tour',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'cantina',
      label: 'Cantina',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'total_amount',
      label: 'Totale',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'order_status',
      label: 'Stato',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'user_phone',
      label: 'Telefono Cliente',
      sortable: true,
      _props: { scope: 'col' },
    },
  ];

  const buildRowsFn = (item) => {
    return {
      _id: item._id,
      user_name: item.name,
      created_at: item.created_at,
      user_phone: item.user_phone,
      order_number: item.order_number,
      total_amount: item.total_amount,
      tour: item.tour,
      cantina: item.cantina,
      order_status: (
        <CBadge style={{ width: '90px' }} color={item.order_status.color}>{item.order_status.label}</CBadge>
      ),
    };
  };

  const onCustomFilter = (tableData, applyFilters) => {
    const newData = JSON.parse(JSON.stringify(tableData));
    let statusFilterKeyWithClause = '';
    // if (statusFilter === '1') { // completato
    //   statusFilterKeyWithClause = '!!^completed_at';
    // }
    if (statusFilter === '2') { // in lavorazione
      statusFilterKeyWithClause = 'products-contact_confirmed_at=null&completed_at=null&products-contact_rejected_at=null&products-deleted_at';
    } else if (statusFilter === '3') { // rifiutato
      statusFilterKeyWithClause = '!!^products-contact_rejected_at';
    } else if (statusFilter === '4') { // confermato
      statusFilterKeyWithClause = '!!^products-contact_confirmed_at=null&products-deleted_at';
    } else if (statusFilter === '5') { // cancellato
      statusFilterKeyWithClause = '!!^products-deleted_at';
    } else if (statusFilter === '6') { // prenotazione con uso voucher
      statusFilterKeyWithClause = '!!^voucher_use_amount';
    }
    newData.customFilter = { key: statusFilterKeyWithClause, value: null };
    return applyFilters(newData);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const StatusFilter = ({ tableData = [], applyFilters = () => {} }) => {
    return (
      <CRow className="align-items-end mb-4">
        <CCol md={12} lg={6}>
          <CForm onSubmit={(e) => { e.preventDefault(); onCustomFilter(tableData, applyFilters); }}>
            <CFormLabel htmlFor="customFilter">Filtro stato prenotazione</CFormLabel>
            <CInputGroup>
              <CFormSelect
                onChange={(e) => {
                  setStatusFilter(e.target.options[e.target.selectedIndex].value);
                }}
                aria-label="Filtro status"
                value={statusFilter}
                options={[
                  {
                    label: 'Seleziona stato',
                    value: '',
                    selected: statusFilter === '',
                  },
                  {
                    label: 'In lavorazione',
                    value: '2',
                    selected: statusFilter === '2',
                  },
                  {
                    label: 'Rifiutato',
                    value: '3',
                    selected: statusFilter === '3',
                  },
                  {
                    label: 'Confermato',
                    value: '4',
                    selected: statusFilter === '4',
                  },
                  {
                    label: 'Cancellato',
                    value: '5',
                    selected: statusFilter === '5',
                  },
                  ...(userGroup === 'superadmin'
                    ? [
                      {
                        label: 'Prenotazione con uso voucher',
                        value: '6',
                        selected: statusFilter === '6',
                      },
                    ]
                    : []),
                ]}
              />
              <CButton disabled={tableData.data === null} type="submit" name="filter" color="primary" id="filter-button">Filtra</CButton>
              <CButton
                disabled={tableData?.data === null || tableData?.search?.length <= 0}
                type="button"
                name="reset"
                color="danger"
                id="filter-button-clear"
                onClick={() => { window.location.reload(); }}
              >
                Cancella
              </CButton>
            </CInputGroup>
          </CForm>
        </CCol>
      </CRow>
    );
  };

  const mapListFn = (item) => {
    const orderStatus = {
      label: 'In lavorazione',
      color: 'warning',
    };

    // if (item.completed_at) {
    //   orderStatus.label = 'Completato';
    //   orderStatus.color = 'success';
    // }

    // if every tour in the order has been rejected or if the remaining
    // tours except the rejected ones have been canceled
    if (item.products.every((product) => {
      return product.contact_rejected_at || product.deleted_at;
    })) {
      orderStatus.label = 'Rifiutato';
      orderStatus.color = 'danger';
    }

    // if every tour in the order has been approved or if the remaining
    // tours except the approved ones have been canceled
    if (item.products.every((product) => {
      return product.contact_confirmed_at || product.deleted_at;
    })) {
      orderStatus.label = 'Confermato';
      orderStatus.color = 'success';
    }

    // if every tour in the order has been canceled
    if (item.products.every((product) => {
      return product.deleted_at;
    })) {
      orderStatus.label = 'Cancellato';
      orderStatus.color = 'primary';
    }

    // Phone and last_name hidden if the user group is 'winery' and the order is not confirmed
    const hideFields = userGroup === 'winery' && orderStatus.label !== 'Confermato';
    return ({
      _id: item._id,
      name: hideFields ? item.user?.first_name : item.user?.name || '-', // if the user group is 'winery' and the order is not confirmed, the surname is hidden (user.name shows both name and surname)
      user_email: item.invoice_data?.email || '-',
      user_phone: hideFields ? '-' : item.user?.shipment_data?.phone || item.invoice_data?.phone || '-',
      order_number: item.order_number || '-',
      // if user group is 'winery' we show total_winery instead of total_amount
      total_amount: utils.formatCurrency({
        number:
          userGroup === 'winery' ? item?.total_winery : item?.total_amount,
      }),
      order_status: orderStatus,
      tour: item.products.reduce((acc, curr) => `${acc} ${curr.name} - `, '').slice(0, -2),
      cantina: item.products.reduce((acc, curr) => `${acc} ${curr.contact_name} - `, '').slice(0, -2),
      created_at: format(new Date(item.confirmed_at ? item.confirmed_at : item.created_at), 'dd/MM/yyyy'),
    });
  };

  const creationBodyFn = () => (
    <CRow md={{ cols: 2, gutter: 2 }}>
      <CCol md={6}>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.name}
              feedback={errors?.name ? composeErrorFormType(errors.name) : null}
              type="text"
              id="region-name"
              label="Regione"
              placeholder="Inserisci regione"
              {...field}
            />
          )}
        />
      </CCol>
    </CRow>
  );

  const invoicingExportModalProps = {
    title: 'Esportazione dati fatturazione',
    description: (
      <p>
        Seleziona l&apos;intervallo di date per l&apos;export dei
        dati di fatturazione.
        <br />
        Il file conterrà ordini di voucher e gli ordini&nbsp;
        <u>confermati</u>
        &nbsp;dalle cantine e pagati dai clienti su Stripe.
      </p>
    ),
    fields: [
      {
        id: 'from_date', label: 'Dal', type: 'date', required: true,
      },
      {
        id: 'to_date', label: 'Al', type: 'date', required: true, max: new Date().toISOString().split('T')[0],
      },
    ],
    submitButtonText: 'Esporta',
    csvFileName: 'fatture',
  };

  return (
    <section id="orders">
      <AppList
        scrollable
        sectionId="orders"
        sectionTitle="Lista Prenotazioni"
        SectionServiceClass={OrdersService}
        sectionPath="/orders"
        mapListFn={mapListFn}
        buildColumnsFn={buildColumnsFn}
        buildRowsFn={buildRowsFn}
        creationTitle="Nuova prenotazione"
        creationBodyFn={() => creationBodyFn()}
        evalCreation={handleSubmit}
        clearCreationModel={() => reset({})}
        CustomFilter={StatusFilter}
        isExportable
        isInvoicingExportable
        initialSortOrder="desc"
        initialSortField="created_at"
        filterOptions={filterOptions}
        invoicingExportModalProps={invoicingExportModalProps}
      />
    </section>
  );
}
export default OrdersList;
