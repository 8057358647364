/* eslint-disable */
import React from "react";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
} from "@coreui/react";

function DashboardTable({tableColumns, tableData, mapTableBody }) {

    const mapTableColumns = (column, index) => {
        return (
            <CTableHeaderCell key={index}>
                {column}
            </CTableHeaderCell>
        );
    }

  return (
    <>
      <CTable align="middle" className="mb-0" hover responsive>
        <CTableHead color="light" className="fw-bolder">
          <CTableRow>
            {tableColumns.map(mapTableColumns)}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {/* {isLoading && (
            <CTableRow>
              <CTableHeaderCell colSpan={tableColumns.length}>
              <div className="my-4">
                <AppLoadingSpinner />
              </div>
              </CTableHeaderCell>
            </CTableRow>
          )} */}
          {tableData.length === 0 && (
            <CTableRow>
              <CTableHeaderCell colSpan={tableColumns.length}>
                Non ci sono risultati.
              </CTableHeaderCell>
            </CTableRow>
          )}
          {tableData.length > 0 && tableData.map(mapTableBody)}
        </CTableBody>
      </CTable>
    </>
  );
}

export default DashboardTable;
