import ApiProxyService from './apiProxyService';

export default class ContactCategoriesService extends ApiProxyService {
  BASE_PATH = '/cities';

  getList({
    paginate,
    page,
    order = 'name',
    sort = 'ASC',
    filters,
    okCallback,
    koCallback,
  }) {
    let queryParams = {
      paginate, page, order_by: order, sort_by: sort,
    };
    const searchFilters = {};
    if (filters) {
      if (filters.search && filters.search.length > 0) {
        searchFilters['??_^city_name'] = filters.search;
      }
      queryParams = {
        ...queryParams, ...searchFilters,
      };
    }
    super.getList(this.BASE_PATH, queryParams, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}`;
    super.getItem(path, okCallback, koCallback);
  }

  addItem(body, okCallback, koCallback) {
    super.addItem(this.BASE_PATH, body, okCallback, koCallback);
  }

  getAll({
    okCallback,
    koCallback,
  }) {
    super.getList(this.BASE_PATH, {}, okCallback, koCallback);
  }
}
