import ApiProxyService from './apiProxyService';

export default class ZonesService extends ApiProxyService {
  BASE_PATH = '/product_categories';

  TOUR_PRODUCT_TYPE_ID = '633dbfe8c843f55df6fa2a0e';

  getList({
    paginate,
    page,
    order = 'name',
    sort = 'ASC',
    filters,
    okCallback,
    koCallback,
  }) {
    let queryParams = {
      paginate,
      page,
      order_by: order,
      sort_by: sort,
      product_type_id: this.TOUR_PRODUCT_TYPE_ID,
      '!!^parent_id': null,
    };
    let searchFilters = {};
    if (filters) {
      searchFilters = { ...filters };
      if (filters.search && filters.search.length > 0) {
        searchFilters['??_^name'] = filters.search;
        delete searchFilters.search;
      }
      queryParams = {
        ...queryParams, ...searchFilters,
      };
    }
    super.getList(this.BASE_PATH, queryParams, okCallback, koCallback);
  }

  getAll({
    okCallback,
    koCallback,
  }) {
    const queryParams = { product_type_id: this.TOUR_PRODUCT_TYPE_ID, '!!^parent_id': null };
    super.getList(this.BASE_PATH, queryParams, okCallback, koCallback);
  }

  addItem(body, okCallback, koCallback) {
    const creationData = { ...body };
    creationData.contact_id = creationData?.contact_id?.value || null;
    creationData.parent_id = creationData?.product_category_id?.value || null;
    creationData.product_type_id = this.TOUR_PRODUCT_TYPE_ID;
    creationData.attributes = {};
    creationData.translations = {
      it: {
        name: creationData.name,
      },
    };
    creationData.media_contents = creationData?.media_contents || [];
    super.addItem(this.BASE_PATH, creationData, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}`;
    super.getItem(path, okCallback, koCallback);
  }

  addMediaContent(itemId, fileData, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}/media_contents`;
    super.uploadItem(path, fileData, okCallback, koCallback);
  }

  deleteMediaContent(itemId, mediaId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}/media_contents/${mediaId}`;
    super.deleteItem(path, okCallback, koCallback);
  }

  deleteItem(deleteInfo, okCallback, koCallback) {
    const deletePromisesArray = [];
    if (typeof deleteInfo === 'string' || typeof deleteInfo === 'number') {
      deletePromisesArray.push(
        new Promise((resolve, reject) => {
          super.deleteItem(`${this.BASE_PATH}/${deleteInfo}`, (res) => resolve(res), (err) => reject(err));
        }),
      );
    } else if (Array.isArray(deleteInfo)) {
      deleteInfo.forEach((currentItem) => {
        deletePromisesArray.push(
          new Promise((resolve, reject) => {
            super.deleteItem(`${this.BASE_PATH}/${currentItem}`, (res) => resolve(res), (err) => reject(err));
          }),
        );
      });
    }
    Promise.all(deletePromisesArray).then((res) => okCallback(res)).catch((err) => koCallback(err));
  }

  updateItem(id, body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}`;
    const parsedBody = { ...body };
    parsedBody.parent_id = parsedBody?.product_category_id?.value || null;
    parsedBody.product_type_id = this.TOUR_PRODUCT_TYPE_ID;
    parsedBody.attributes = {};
    parsedBody.translations = {
      it: {
        name: parsedBody.name,
        description: parsedBody.description,
      },
    };
    parsedBody.media_contents = parsedBody?.media_contents || [];
    super.updateItem(path, parsedBody, okCallback, koCallback);
  }
}
