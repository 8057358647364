/* eslint-disable */
import Dashboard from "./views/dashboard/Dashboard";
import ToursList from "./modules/tours/views/ToursList";
import ToursDetail from "./modules/tours/views/ToursDetail";
import ContactsList from "./modules/contacts/views/ContactsList";
import ContactsDetail from "./modules/contacts/views/ContactsDetail";
import FeedsList from "./modules/feeds/views/FeedsList";
import FeedsDetail from "./modules/feeds/views/FeedsDetail";
import ProductTypesList from "./modules/productTypes/views/ProductTypesList";
import ProductTypeDetail from "./modules/productTypes/views/ProductTypeDetail";
import RegionsList from "./modules/regions/views/RegionsList";
import RegionsDetail from "./modules/regions/views/RegionsDetail";
import TagsList from "./modules/tags/views/TagsList";
import TagsDetail from "./modules/tags/views/TagsDetail";
import ZonesList from "./modules/zones/views/ZonesList";
import ZonesDetail from "./modules/zones/views/ZonesDetail";
import NotFound from "./views/pages/page404/Page404";
import ContactCategoriesList from "./modules/contactCategories/views/ContactCategoriesList";
import ContactCategoriesDetail from "./modules/contactCategories/views/ContactCategoriesDetail";
import UsersList from "./modules/users/views/UsersList";
import UsersDetail from "./modules/users/views/UsersDetail";
import UserProfile from "./modules/users/views/UserProfile";
import OrdersList from "./modules/orders/views/OrdersList";
import OrdersDetail from "./modules/orders/views/OrdersDetail";
import VouchersList from "./modules/vouchers/views/VouchersList";
import VouchersDetail from "./modules/vouchers/views/VouchersDetail";
import ForgotPassword from "./views/pages/forgot-password/ForgotPassword";
import ResetPassword from "./views/pages/reset-password/ResetPassword";
import SpecialVouchersList from "./modules/specialVouchers/views/SpecialVouchersList";
import SpecialVouchersDetail from "./modules/specialVouchers/views/SpecialVouchersDetail";
import PromoCodesList from "./modules/discountCodes/views/Packages/PromoCodesList";
import PromoCodeDetail from "./modules/discountCodes/views/Packages/PromoCodeDetail";
// FIXME: Permessi giusti per zones e per regions

const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    element: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    element: ResetPassword,
  },
  {
    path: "/users/profile",
    name: "Il tuo profilo",
    element: UserProfile,
  },
  {
    path: "*",
    name: "NotFound",
    element: NotFound,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: "/feeds",
    name: "Feeds",
    element: FeedsList,
    roles: ["superadmin"],
    permissions: ["feeds_view"],
  },
  {
    path: "/feeds/:id",
    name: "Feeds Detail",
    element: FeedsDetail,
    roles: ["superadmin"],
    permissions: ["feeds_view", "feeds_edit"],
  },
  {
    path: "/winery",
    name: "Cantine",
    element: ContactsList,
    roles: ["superadmin", "admin", "editor"],
    permissions: ["contacts_view"],
  },
  {
    path: "/winery/:id",
    name: "Dettaglio cantina",
    element: ContactsDetail,
    roles: ["superadmin", "admin", "winery"],
    permissions: ["contacts_view", "contacts_edit"],
  },
  {
    path: "/winery/:id",
    name: "Dettaglio cantina",
    element: ContactsDetail,
    roles: ["editor"],
    permissions: ["contacts_view"],
  },
  {
    path: "/product-types",
    name: "Product Types",
    element: ProductTypesList,
    roles: ["superadmin"],
    permissions: ["product_types_view"],
  },
  {
    path: "/product-types/:id",
    name: "Product Type",
    element: ProductTypeDetail,
    roles: ["superadmin"],
    permissions: ["product_types_view", "product_types_edit"],
  },
  {
    path: "/regions",
    name: "Regions",
    roles: ["superadmin"],
    element: RegionsList,
  },
  {
    path: "/regions/:id",
    name: "Regions Details",
    element: RegionsDetail,
    roles: ["superadmin"],
  },
  {
    path: "/tags",
    name: "Tags",
    element: TagsList,
    roles: ["superadmin"],
    permissions: ["tags_view"],
  },
  {
    path: "/tags/:id",
    name: "Tags Details",
    element: TagsDetail,
    roles: ["superadmin"],
    permissions: ["tags_view", "tags_edit"],
  },
  {
    path: "/tours",
    name: "Tours",
    element: ToursList,
    roles: ["superadmin", "admin", "editor"],
    permissions: ["products_view"],
  },
  {
    path: "/tours/:id",
    name: "Dettaglio Tour",
    element: ToursDetail,
    roles: ["superadmin", "admin", "winery"],
    permissions: ["products_view", "products_edit"],
  },
  {
    path: "/tours/:id",
    name: "Dettaglio Tour",
    element: ToursDetail,
    roles: ["editor"],
    permissions: ["products_view"],
  },
  {
    path: "/orders",
    name: "Prenotazioni",
    element: OrdersList,
    roles: ["superadmin", "admin", "winery", "editor"],
    permissions: ["orders_view"],
  },
  {
    path: "/orders/:id",
    name: "Dettaglio prenotazione",
    element: OrdersDetail,
    roles: ["superadmin", "admin", "winery"],
    permissions: ["orders_view", "orders_edit"],
  },
  {
    path: "/orders/:id",
    name: "Dettaglio prenotazione",
    element: OrdersDetail,
    roles: ["editor"],
    permissions: ["orders_view"],
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    element: VouchersList,
    roles: ["superadmin"],
    permissions: ["vouchers_view"],
  },
  {
    path: "/vouchers/:id",
    name: "Dettaglio voucher",
    element: VouchersDetail,
    roles: ["superadmin"],
    permissions: ["vouchers_view", "vouchers_edit"],
  },
  {
    path: "/special-vouchers",
    name: "Special Vouchers",
    element: SpecialVouchersList,
    roles: ["superadmin"],
    permissions: ["special_vouchers_view"],
  },
  {
    path: "/special-vouchers/:id",
    name: "Dettaglio voucher speciale",
    element: SpecialVouchersDetail,
    roles: ["superadmin"],
    permissions: ["special_vouchers_view", "special_vouchers_edit"],
  },
  {
    path: "/promo-codes",
    name: "Codici Sconto",
    element: PromoCodesList,
    roles: ["superadmin"],
    permissions: ["promo_codes_view"],
  },
  {
    path: "/promo-codes/:id",
    name: "Dettaglio codice sconto",
    element: PromoCodeDetail,
    roles: ["superadmin"],
    permissions: ["promo_codes_view", "promo_codes_edit"],
  },
  {
    path: "/zones",
    name: "Zones",
    element: ZonesList,
    roles: ["superadmin"],
  },
  {
    path: "/zones/:id",
    name: "Zones Details",
    element: ZonesDetail,
    roles: ["superadmin"],
  },
  {
    path: "/contact-categories",
    name: "Categorie contatto",
    element: ContactCategoriesList,
    roles: ["superadmin"],
    permissions: ["contact_categories_view"],
  },
  {
    path: "/contact-categories/:id",
    name: "Dettaglio categoria contatto",
    element: ContactCategoriesDetail,
    roles: ["superadmin"],
    permissions: ["contact_categories_view", "contact_categories_edit"],
  },
  {
    path: "/users",
    name: "Utenti",
    element: UsersList,
    roles: ["superadmin"],
    permissions: ["users_view"],
  },
  {
    path: "/users/:id",
    name: "Dettaglio utente",
    element: UsersDetail,
    roles: ["superadmin"],
    permissions: ["users_view", "users_edit"],
  },
];

export default routes;
