/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes, { string } from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import {
  CForm,
  CFormInput,
  CInputGroup,
  CRow, CCol, CFormLabel, CInputGroupText, CFormCheck,
  CFormSwitch,
  CFormTextarea,
} from '@coreui/react';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import TagsService from 'src/services/api/TagsService';
import AppMultiData from 'src/components/ui/MultiData/AppMultiData';
import toast from 'react-hot-toast';
import ServiceForm from './ServiceForm';
import WarningForm from './WarningForm';

const PackageForm = ({
  defaultValues, submit, formId, parentProps, userGroup,
}) => {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValues || {
      idx: null,
      id: null,
      package_title: '',
      package_abstract: '',
      package_description: '',
      name_tag: null,
      price: '',
      fee_amount: '',
      price_type_tag: null,
      included_services: [],
      warnings: [],
      quota_100: false,
    },
  });
  const [state, setState] = useState(defaultValues || {
    id: null,
    idx: null,
    package_title: '',
    package_abstract: '',
    package_description: '',
    name_tag: null,
    price: '',
    fee_amount: '',
    price_type_tag: null,
    included_services: [],
    warnings: [],
    quota_100: false,
  });

  const loadTags = (filter) => new Promise((resolve) => {
    const tagsService = new TagsService();
    const okGetTags = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.tag }
        ));
      }
      resolve(responseData);
    };
    const koGetTags = () => resolve([]);
    const filters = {
      feed_id: '63750cc287c76e0016974c14', // Feed di nome "Pacchetto"
    };
    if (filter.length > 0) filters['??^tag'] = filter;
    tagsService.getList({
      paginate: 5,
      page: 1,
      filters,
      okCallback: (res) => okGetTags(res),
      koCallback: (err) => koGetTags(err),
    });
  });

  const loadPriceTypes = (filter) => new Promise((resolve) => {
    const tagsService = new TagsService();
    const okGetTags = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.tag }
        ));
      }
      resolve(responseData);
    };
    const koGetTags = () => resolve([]);
    const filters = {
      feed_id: '63751cfe87c76e0016974c17', // Feed di nome "Tipo prezzo pacchetto"
    };
    if (filter.length > 0) filters['??^tag'] = filter;
    tagsService.getList({
      paginate: 5,
      page: 1,
      filters,
      okCallback: (res) => okGetTags(res),
      koCallback: (err) => koGetTags(err),
    });
  });

  const insertService = (data, formProps) => {
    const newState = { ...getValues() };
    if (!Array.isArray(newState.included_services)) {
      newState.included_services = [];
    }

    newState.included_services?.push(data.name);
    setValue('included_services', newState.included_services);
    setState(newState);
    formProps.closeModal();
  };

  const editService = (data, formProps) => {
    const newState = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      const clearData = { ...data };
      delete clearData.idx;
      newState.included_services[data.idx] = (clearData.name);
      setValue('included_services', newState.included_services);
      setState(newState);
    }
    formProps.closeModal();
  };

  const deleteService = (data) => {
    const newState = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      newState.included_services.splice(data.idx, 1);
      setValue('included_services', [...newState.included_services]);
      setState(newState);
    }
  };

  const insertWarning = (data, formProps) => {
    const newState = { ...getValues() };
    newState.warnings.push(data.name);
    setValue('warnings', [...newState.warnings]);
    setState(newState);
    formProps.closeModal();
  };

  const editWarning = (data, formProps) => {
    const newState = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      const clearData = { ...data };
      delete clearData.idx;
      newState.warnings[data.idx] = (clearData.name);
      setValue('warnings', [...newState.warnings]);
      setState(newState);
    }
    formProps.closeModal();
  };

  const deleteWarning = (data) => {
    const newState = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      newState.warnings.splice(data.idx, 1);
      setValue('warnings', [...newState.warnings]);
      setState(newState);
    }
  };

  const toggleQuota100 = (e) => {
    const newState = { ...getValues() };
    if (e.target.checked) {
      newState.quota_100 = true;
    } else {
      newState.quota_100 = false;
    }
    setValue('quota_100', newState.quota_100);
    setState(newState);
  };

  const validateForm = (data) => {
    const { fee_amount, price, quota_100 } = data;
    const feeAmount = Number(fee_amount);
    const basePrice = Number(price);
    if (quota_100 && feeAmount === basePrice) {
      return {
        result: false,
        message: 'Commissione e prezzo base non possono essere uguali quando la quota 100% è selezionata',
      };
    } if (feeAmount > basePrice) {
      return {
        result: false,
        message: 'La commissione non può essere maggiore del prezzo base',
      };
    }
    return { result: true };
  };
  const onSubmit = (data) => {
    const validation = validateForm(data);
    if (!validation.result) {
      return toast.error(validation.message);
    }
    return submit(data);
  };

  useEffect(() => {
    if (typeof parentProps?.show !== 'undefined') {
      if (parentProps.show === false) {
        reset();
        setState(defaultValues || {});
      } else {
        const newData = {
          id: parentProps?.target?.data?.id,
          package_title: parentProps?.target?.data?.package_title || '',
          package_abstract: parentProps?.target?.data?.package_abstract || '',
          package_description: parentProps?.target?.data?.package_description || '',
          name_tag: parentProps?.target?.data?.name_tag || null,
          price: parentProps?.target?.data?.price || '',
          fee_amount: parentProps?.target?.data?.fee_amount || '',
          price_type_tag: parentProps?.target?.data?.price_type_tag || null,
          included_services: parentProps?.target?.data?.included_services || [],
          warnings: [...(parentProps?.target?.data?.warnings || [])],
          quota_100: parentProps?.target?.data?.quota_100 || false,
        };
        reset(newData);
        setState(newData);
      }
    }
  }, [parentProps.show]);

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(onSubmit)}>
        <CCol md={12}>
          <Controller
            name="package_title"
            control={control}
            render={({ field }) => (
              <>
                <CFormTextarea
                  maxLength={165}
                  label={(
                    <span className="fs-5">Titolo pacchetto</span>
                  )}
                  id="package-title"
                  rows="1"
                  {...field}
                />
                <p className="text-align-end">
                  <small>Max. 165 caratteri</small>
                </p>
              </>
            )}
          />
        </CCol>
        <CCol md={12}>
          <Controller
            name="package_description"
            control={control}
            render={({ field }) => (
              <>
                <CFormTextarea
                  maxLength={1150}
                  label={(
                    <span className="fs-5">
                      Descrizione del pacchetto
                    </span>
                 )}
                  id="package-description"
                  rows="2"
                  {...field}
                />
                <p className="text-align-end">
                  <small>Max. 1150 caratteri</small>
                </p>
              </>
            )}
          />
        </CCol>
        <CRow>
          <CCol lg={5} md={6} sm={12}>
            <Controller
              name="name_tag"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <CFormLabel htmlFor="package-name_tag">Nome</CFormLabel>
                  <AsyncSelect
                    inputId="package-name_tag"
                    isClearable
                    defaultOptions
                    loadOptions={loadTags}
                    {...field}
                  />
                  {errors.name_tag ? (
                    <div className="invalid-feedback d-block">
                      {composeErrorFormType(errors.name_tag)}
                    </div>
                  ) : null}
                </>
              )}
            />
            <div className="mb-3" />
          </CCol>
          <CCol md="6" sm="12" lg="4">
            <CFormLabel htmlFor="tour-fee_amount">Commissione</CFormLabel>
            <Controller
              name="fee_amount"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <CInputGroup>
                  <CFormInput
                    readOnly={
                      userGroup !== 'admin' && userGroup !== 'superadmin'
                    } // only admin and superadmin can edit this field
                    invalid={!!errors.price}
                    type="number"
                    min={0}
                    id="fee_amount"
                    className="text-align-end"
                    aria-describedby="package-price_append"
                    placeholder="Inserisci commissione"
                    {...field}
                  />
                  <CInputGroupText id="tour-price_append">€</CInputGroupText>
                </CInputGroup>
              )}
            />
            {errors.fee_amount ? (
              <div className="invalid-feedback d-block">
                {composeErrorFormType(errors.fee_amount)}
              </div>
            ) : null}
            <div className="mb-3" />
          </CCol>
          <CCol
            md={6}
            sm={12}
            lg={3}
            className="d-flex align-items-center  justify-content-lg-center justify-content-start mt-lg-3 mb-lg-0 mb-3"
          >
            <Controller
              name="quota_100"
              control={control}
              render={({ field }) => (
                <CFormSwitch
                  disabled={userGroup !== 'superadmin' && userGroup !== 'admin'} // only superadmin or admin can edit this field
                  id="package-quota_100"
                  label="Quota 100%"
                  checked={field.value}
                  onChange={toggleQuota100}
                  size="lg"
                  style={{ minWidth: '40px', marginRight: '10px' }}
                />
              )}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md={6} sm={12}>
            <CFormLabel htmlFor="tour-base_price">Prezzo base</CFormLabel>
            <Controller
              name="price"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <CInputGroup>
                  <CFormInput
                    invalid={!!errors.price}
                    type="number"
                    id="price"
                    className="text-align-end"
                    aria-describedby="package-price_append"
                    placeholder="Inserisci prezzo"
                    {...field}
                  />
                  <CInputGroupText id="tour-price_append">€</CInputGroupText>
                </CInputGroup>
              )}
            />
            {errors.price ? (
              <div className="invalid-feedback d-block">
                {composeErrorFormType(errors.price)}
              </div>
            ) : null}
            <div className="mb-3" />
          </CCol>
          <CCol md={6} sm={12}>
            <Controller
              name="price_type_tag"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <CFormLabel htmlFor="package-price_type_tag">
                    Tipologia prezzo
                  </CFormLabel>
                  <AsyncSelect
                    inputId="package-price_type_tag"
                    isClearable
                    defaultOptions
                    loadOptions={loadPriceTypes}
                    {...field}
                  />
                  {errors.price_type_tag ? (
                    <div className="invalid-feedback d-block">
                      {composeErrorFormType(errors.price_type_tag)}
                    </div>
                  ) : null}
                </>
              )}
            />
            <div className="mb-3" />
          </CCol>
        </CRow>
      </CForm>
      <AppMultiData
        buttonAddString="Aggiungi Servizio"
        className="mb-4"
        title="Servizi inclusi"
        item="Servizio"
        formId="servizio-pacchetto"
        createFormComponent={(CreateFormProps) => ServiceForm({
          formId: 'create_servizio-pacchetto',
          submit: (data) => insertService(data, CreateFormProps),
          parentProps: {
            show: CreateFormProps.show,
          },
        })}
        editFormComponent={(EditFormProps) => ServiceForm({
          formId: 'edit_servizio-pacchetto',
          submit: (data) => editService(data, EditFormProps),
          parentProps: {
            show: EditFormProps.show,
            target: EditFormProps.target,
          },
        })}
        deleteFunction={(deleteProps) => deleteService(deleteProps)}
        data={state.included_services || []}
        singleField
        modalAlign="center"
      />
      <AppMultiData
        buttonAddString="Aggiungi Avvertenza"
        className="mb-4"
        title="Avvertenze pacchetto"
        item="Avvertenza"
        formId="avvertenza-pacchetto"
        createFormComponent={(CreateFormProps) => WarningForm({
          formId: 'create_avvertenza-pacchetto',
          submit: (data) => insertWarning(data, CreateFormProps),
          parentProps: {
            show: CreateFormProps.show,
          },
        })}
        editFormComponent={(EditFormProps) => WarningForm({
          formId: 'edit_avvertenza-pacchetto',
          submit: (data) => editWarning(data, EditFormProps),
          parentProps: {
            show: EditFormProps.show,
            target: EditFormProps.target,
          },
        })}
        deleteFunction={(deleteProps) => deleteWarning({
          id: deleteProps.target,
        })}
        data={state.warnings || null}
        singleField
        modalAlign="center"
      />
    </>
  );
};

PackageForm.propTypes = {
  defaultValues: PropTypes.shape({
    name_tag: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    price: PropTypes.number,
    price_type_tag: PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
    included_services: PropTypes.arrayOf({
      _id: string,
      description: string,
    }) || null,
  }),
  formId: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  parentProps: PropTypes.shape({
    show: PropTypes.bool,
    target: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.number,
        package_title: PropTypes.string,
        package_abstract: PropTypes.string,
        package_description: PropTypes.string,
        name_tag: PropTypes.string,
        price: PropTypes.string,
        price_type_tag: PropTypes.string,
        included_services: PropTypes.arrayOf(PropTypes.string),
        warnings: PropTypes.arrayOf(PropTypes.string),
      }),
    }) || null,
  }),
  userGroup: PropTypes.string,
};

PackageForm.defaultProps = {
  defaultValues: {
    id: null,
    package_title: '',
    package_abstract: '',
    package_description: '',
    name_tag: null,
    price: '',
    price_type_tag: null,
    included_services: [],
    warnings: [],
  },
  parentProps: {},
  userGroup: null,
};

export default PackageForm;
