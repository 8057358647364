import React from 'react';
import PropTypes from 'prop-types';
import { CFormSelect } from '@coreui/react';

function YearSelector({ changeSelectedYear, startingYear }) {
  const mapYears = () => {
    const currYear = new Date().getFullYear();
    const years = [];
    for (let i = currYear; i >= startingYear; i -= 1) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return years;
  };

  return (
    <>
      <h4 className="card-title">Seleziona anno</h4>
      <CFormSelect
        aria-label="Default select example"
        size="lg"
        onChange={changeSelectedYear}
        className="w-auto"
      >
        {mapYears()}
      </CFormSelect>
    </>
  );
}

YearSelector.propTypes = {
  changeSelectedYear: PropTypes.func.isRequired,
  startingYear: PropTypes.number,
};

YearSelector.defaultProps = {
  startingYear: 2022,
};

export default YearSelector;
